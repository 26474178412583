function getTasksCompleted(params){
  return `${params.row.completedTasks || ''} / ${params.row.assignedTasks || ''}` ;
}

/* 
export const employeeScoresColumns = [
    { field: "firstName", headerName: "First Name", width: 120 ,align:'center', headerAlign: 'center'},
    { field: "lastName", headerName: "Last Name", width: 120 ,align:'center', headerAlign: 'center'},
    { field: "employeeEmail", headerName: "Employee Email", type:"date",width: 220 ,align:'center', headerAlign: 'center'},
    { field: "tasksCompleted", headerName: "Tasks Completed", width: 130, align:'center', headerAlign: 'center',
      renderCell: (params) => <a href="" onClick={taskCom}>{params.row.completedTasks}/{params.row.assignedTasks}</a> },
    { field: "openness", headerName: "Openness", type:"number", width: 100,align:'center' , headerAlign: 'center'},
    { field: "conscientiousness", headerName: "Conscientiousness", type:"number", width: 160,align:'center', headerAlign: 'center' },
    { field: "extraversion", headerName: "Extraversion", type:"number", width: 120,align:'center', headerAlign: 'center' },
    { field: "agreeableness", headerName: "Agreeableness", type:"number", width: 120,align:'center', headerAlign: 'center' },
    { field: "neuroticism", headerName: "Neuroticism", type:"number", width: 120,align:'center', headerAlign: 'center' }
     ];
      */
   //Temp data
     export const employeeScoresRows = [
      {id: "1",  firstName: "Shashank", lastName:"Singhal", employeeEmail: "abc@abc.com", openness: 1, conscientiousness: 2,  extraversion:3,agreeableness:4, neuroticism:5 },
      {id: "2",  firstName: "Shashank", lastName:"Singhal", employeeEmail: "abc@abc.com",  openness: 1, conscientiousness: 2,  extraversion:3,agreeableness:4, neuroticism:5 },
      {id: "3",  firstName: "Shashank", lastName:"Singhal", employeeEmail: "abc@abc.com",  openness: 1, conscientiousness: 2,  extraversion:3,agreeableness:4, neuroticism:5 },
      {id: "4",  firstName: "Shashank", lastName:"Singhal",  employeeEmail: "abc@abc.com",  openness: 1, conscientiousness: 2,  extraversion:3,agreeableness:4, neuroticism:5 },
      {id: "5",  firstName: "Shashank", lastName:"Singhal",  employeeEmail: "abc@abc.com",  openness: 1, conscientiousness: 2,  extraversion:3,agreeableness:4, neuroticism:5 },
      {id: "6",  firstName: "Shashank", lastName:"Singhal",  employeeEmail: "abc@abc.com",  openness: 1, conscientiousness: 2,  extraversion:3,agreeableness:4, neuroticism:5 },
     ];