import { Grid, Paper, Typography } from "@mui/material";
import axios from "axios";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import Navbar from "../../components/navbar/Navbar";
import Task from "../../components/task/Task";
import "./taskpage.scss";
import jwtDecode from 'jwt-decode';

function TaskPage(props) {

  const[assessmentTasks, setAssessmentTasks] = useState([]);

  useEffect(() => {
    const token = localStorage.getItem('jwt_employee');
    const decodedToken = jwtDecode(token);
    const expirationTime = decodedToken.exp * 1000;
    const currentTime = new Date().getTime();
    if (currentTime > expirationTime) {
      localStorage.removeItem('jwt_employee');
    }
    axios
      .get(process.env.REACT_APP_ROOT_URL+"/api/v1/assessment/tasks/assessor", {
        params: { companyId: localStorage.getItem("companyId") ? localStorage.getItem("companyId") : 123,
        assessorId: localStorage.getItem("employeeId") ? localStorage.getItem("employeeId") : 1 },
        headers: { Authorization: localStorage.getItem("authHeader") },
      })
      .then((response) => {
        console.log(response.data);
        setAssessmentTasks(response.data);
      });
    },[]);

  return (
    <div>
    <Navbar type="employee" />
    <Paper elevation={10} className="taskPageMain">
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        spacing={2}
        
      >
        <Grid item xs><Typography variant="h4">My Tasks</Typography></Grid>
        <Grid item xs className="rowItems">
            <Grid container spacing = {3} direction="column" justifyContent="center" alignItems = "stretch" >
              {assessmentTasks.map((assessmentTask) => (
                assessmentTask.status !== "COMPLETED" && 
                <Grid item xs><Task assesseeName={assessmentTask.assessee.firstName +  " " +assessmentTask.assessee.lastName} questions = {assessmentTask.questions} assessment={assessmentTask.assessment} assessmentTaskId = {assessmentTask.id} /></Grid>
                ))}
            </Grid>
        </Grid>


      </Grid>
    </Paper>
    </div>
  );
}

export default TaskPage;
