import {
  Alert,
  Autocomplete,
  Button,
  Grid,
  Snackbar,
  TextField
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { validateToken } from "../../utils/localStorageHandling";
import "./assessmentGroup.scss";

function AssessmentGroup(props) {
  const [groupId, setGroupId] = useState();
  const [groupName, setGroupName] = useState(null);
  const [groupDescription, setGroupDescription] = useState();
  const [groupMembers, setGroupmembers] = useState(
    props.groupMembers ? props.groupMembers : []
  );
  const [employeeData, setEmployeeData] = useState();
  const [groupsData, setGroupsData] = useState();
  const [successSnackBar, setSuccessSnackBar] = useState(false);
  const [errorSnackbar, setErrorSnackBar] = useState(false);
  const [errorMessage, setErrorMessage] = useState(props.type==="create" ? "Error in creating Team.Please contact Support !" : "Error in updating Team.Please contact Support !");
  const navigate = useNavigate();

    const clearForm = () => {
      setGroupId(null);
      setGroupName(null);
      setGroupDescription();
      setGroupmembers([]);
    }

  useEffect(() => {  
    validateToken("admin", navigate);
    axios
      .get(process.env.REACT_APP_ROOT_URL+"/api/v1/person/company", {
        params: { companyId: localStorage.getItem("companyId") ? localStorage.getItem("companyId") : 123 },
        headers: { Authorization: localStorage.getItem("authHeader") },
      })
      .then((response) => {
        setEmployeeData(
          response.data.map((employee) => ({
            ...employee,
            label:
              employee.firstName +
              " " +
              employee.lastName +
              " (" +
              employee.email +
              ")",
          }))
        );
      });

    if (props.type === "edit") {
      axios
        .get(process.env.REACT_APP_ROOT_URL+"/api/v1/group", {
          params: { companyId: localStorage.getItem("companyId") ? localStorage.getItem("companyId") : 123 },
        })
        .then((response) => {
          setGroupsData(
            response.data.map((group) => ({
              ...group,
              label: group.name,
            }))
          );
        });
    }
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();
    if (props.type === "create") {
      axios
        .post(process.env.REACT_APP_ROOT_URL+"/api/v1/group", {
          name: groupName,
          description: groupDescription,
          assessmentGroupMembers: groupMembers,
          companyId: localStorage.getItem("companyId") ? localStorage.getItem("companyId") : 123,
        })
        .then(function (response) {
          console.log(response);
          setSuccessSnackBar(true);
          clearForm();
        })
        .catch(function (error) {
          console.log(error);
          setErrorSnackBar(true);
          setErrorMessage(error.response.data.message);
        });
    }
    if (props.type === "edit") {
      axios
        .put(process.env.REACT_APP_ROOT_URL+"/api/v1/group", {
          id: groupId,
          name: groupName,
          description: groupDescription,
          assessmentGroupMembers: groupMembers,
          companyId: localStorage.getItem("companyId") ? localStorage.getItem("companyId") : 123,
        })
        .then(function (response) {
          console.log(response);
          setSuccessSnackBar(true);
          clearForm();
        })
        .catch(function (error) {
          console.log(error);
          setErrorSnackBar(true);
        });
    }
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSuccessSnackBar(false);
    setErrorSnackBar(false);
  };

  return (
    <div className="assessmentGroupCard">
      <form onSubmit={handleSubmit}>
        <Grid
          container
          direction="column"
          spacing={3}
          justifyContent="center"
          alignItems="flex-start"
        >
          <Grid item xs className="groupGridItem">
            {props.type === "create" && (
              <TextField
                required
                fullWidth
                id="standard-basic"
                label="Team Name"
                variant="outlined"
                value={groupName ? groupName : ""}
                onChange={(event, newValue) => {
                  setGroupName(event.target.value);
                }}
              />
            )}
            {props.type === "edit" && (
              <Autocomplete
                disablePortal
                id="search-group"
                value={groupName}
                //isOptionEqualToValue={(option, value) => option.id === value.id}
                onChange={(event, newValue) => {
                  //console.log(newValue);
                  setGroupName(newValue.name);
                  setGroupmembers(
                    newValue.assessmentGroupMembers.map((employee) => ({
                      ...employee,
                      label:
                        employee.firstName +
                        " " +
                        employee.lastName +
                        " (" +
                        employee.email +
                        ")",
                    }))
                  );
                  setGroupDescription(newValue.description);
                  setGroupId(newValue.id);
                }}
                options={groupsData ? groupsData : []}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Search Teams..."
                    required
                  />
                )}
              />
            )}
          </Grid>
          <Grid item xs className="groupGridItem">
            <TextField
              required
              fullWidth
              id="standard-basic"
              label="Team Description"
              variant="outlined"
              value={groupDescription ? groupDescription : ""}
              onChange={(event, newValue) => {
                setGroupDescription(event.target.value);
              }}
            />
          </Grid>
          <Grid item xs className="groupGridItem">
            <label>Members</label>
            <Autocomplete
              required
              multiple
              id="group-members"
              options={employeeData ? employeeData : []}
              getOptionLabel={(option) => option.label}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              //defaultValue={[top100Films[13]]}
              renderInput={(params) => (
                <TextField {...params} required={groupMembers.length === 0} placeholder="Search Assessors..." />
              )}
              value={groupMembers}
              onChange={(event, newValue) => {
                setGroupmembers(newValue);
              }}
            />
          </Grid>
          {props.type === "create" && (
            <Grid item xs className="groupGridItem">
              <Button
                type="submit"
                variant="contained"
                className="createButtonGroup"
              >
                Create
              </Button>
            </Grid>
          )}
          {props.type === "edit" && (
            <Grid item xs className="groupGridItem">
              <Button
                type="submit"
                variant="contained"
                className="createButtonGroup"
              >
                Update
              </Button>
            </Grid>
          )}
        </Grid>
      </form>
      <Snackbar
        open={successSnackBar}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert onClose={handleClose} severity="success" sx={{ width: "100%" }}>
          Group {props.type==="create" ? "Created" : "Updated"} Successfully!
        </Alert>
      </Snackbar>
      <Snackbar
        open={errorSnackbar}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
          {errorMessage}
        </Alert>
      </Snackbar>
    </div>
  );
}

export default AssessmentGroup;
