import "./assignAssessmentTeam.scss";
import Navbar from "../../components/navbar/Navbar";
import Sidebar from "../../components/sidebar/Sidebar";
import { Alert, Autocomplete, Button, Grid, Snackbar, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import axios from "axios";
import jwtDecode from 'jwt-decode';
import { clearStorage, validateToken } from "../../utils/localStorageHandling";
import { useNavigate } from "react-router-dom";

function AssignAssessmentTeam(props) {
  const top100Films = [
    { id: 1, label: "The Shawshank Redemption" },
    { id: 2, label: "A Clockwork Orange" },
    { id: 3, label: "Like Stars on Earth" },
    { id: 4, label: "Taxi Driver" },
    { id: 5, label: "Lawrence of Arabia" },
  ];
  const [assessment, setAssessment] = useState(null);
  const [groups, setGroups] = useState([]);
  const [assessmentData, setAssessmentData] = useState();
  const [groupData, setGroupData] = useState();
  const [successSnackBar, setSuccessSnackBar] = useState(false);
  const [errorSnackbar, setErrorSnackBar] = useState(false);
  const navigate = useNavigate();

  const clearForm = () => {
    setAssessment(null);
    setGroups([]);
  }

  useEffect(() => {
    validateToken("admin", navigate);
    //const fetchEmployee = axios.get(process.env.REACT_APP_ROOT_URL+"/api/v1/person/company",{ params: { companyId:  localStorage.getItem("companyId") ? localStorage.getItem("companyId") : 123 },headers: { Authorization: localStorage.getItem("authHeader") },});
    const fetchGroup = axios.get(process.env.REACT_APP_ROOT_URL+"/api/v1/group",{ params: { companyId:  localStorage.getItem("companyId") ? localStorage.getItem("companyId") : 123 },headers: { Authorization: localStorage.getItem("authHeader") },});
    const fetchAssessment = axios.get(process.env.REACT_APP_ROOT_URL+"/api/v1/assessment/company",{ params: { companyId:  localStorage.getItem("companyId") ? localStorage.getItem("companyId") : 123 },headers: { Authorization: localStorage.getItem("authHeader") },});
    axios.all([fetchGroup, fetchAssessment]).then(axios.spread((groupFetch, assessmentFetch) => {
      setGroupData(
        groupFetch.data.map((group) => ({
          ...group,
          label: group.name,
        }))
      );
      setAssessmentData(
        assessmentFetch.data.map((assessment) => ({
          ...assessment,
          label: assessment.assessmentName,
        }))
      ); 
    })).catch(error => console.log(error))
  },[]);

  const handleSubmit = (event) => {
    event.preventDefault();
    axios
      .post(process.env.REACT_APP_ROOT_URL+"/api/v1/assessment/assignTeam", groups,{
        headers: { Authorization: localStorage.getItem("authHeader") },
        params:{assessmentId : assessment.id,
        companyId: localStorage.getItem("companyId") ? localStorage.getItem("companyId") : 123}
      })
      .then(function (response) {
        console.log(response);
        setSuccessSnackBar(true);
        clearForm();
      })
      .catch(function (error) {
        console.log(error);
        setErrorSnackBar(true);
      });
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSuccessSnackBar(false);
    setErrorSnackBar(false);
  };

  return (
    <div className="assignAssessment">
      <Navbar />
      <div className="assignAssessmentContainer">
        <Sidebar selectedIndex={4} />
        <div className="contentArea" style = {{width: '50%'}}>
          <div className="topAssignAssessment">
            <h1>Assign Assessment (Team)</h1>
          </div>
          <div className="bottomAssignAssessment">
          <form onSubmit={handleSubmit}>
            <Grid
              container
              direction="column"
              spacing={3}
              justifyContent="center"
              alignItems="flex-start"
            >
              <Grid item xs className="gridItem">
                <label>Assessment Name</label>
                <Autocomplete
                  disablePortal
                  id="assessment-name"
                  value={assessment}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  onChange={(event, newValue) => {
                    //console.log(newValue);
                    setAssessment(newValue);
                  }}
                  options={assessmentData ? assessmentData : []}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Search Assessments..."
                      required
                    />
                  )}
                />
              </Grid>
              <Grid item xs className="gridItem">
                <label>Teams</label>
                <Autocomplete
                  multiple
                  id="group-name"
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  value={groups}
                  onChange={(event, newValue) => {
                    console.log(newValue);
                    setGroups(newValue);
                  }}
                  options={groupData ? groupData : []}
                  getOptionLabel={(option) => option.label}
                  //defaultValue={[top100Films[13]]}
                  renderInput={(params) => (
                    <TextField {...params} placeholder="Search Teams..." />
                  )}
                />
              </Grid>
              <Grid item xs className="gridItem">
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  className="assignButton"
                >
                  Assign
                </Button>
              </Grid>
              {/* <button type="button" className="createButton">Assign</button> */}
            </Grid>
            </form>
            <Snackbar
        open={successSnackBar}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical:'top', horizontal:'right' }}
      >
        <Alert onClose={handleClose} severity="success" sx={{ width: "100%" }}>
          Assessment Assigned Successfully!
        </Alert>
      </Snackbar>
      <Snackbar
        open={errorSnackbar}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical:'top', horizontal:'right' }}
      >
        <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
        Error in Assigning Assessment.Please contact Support !
        </Alert>
      </Snackbar>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AssignAssessmentTeam;
