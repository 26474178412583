import Person4Icon from "@mui/icons-material/Person4";
import { DataGrid,GridToolbar } from "@mui/x-data-grid";
import axios from "axios";
import { useEffect, useState } from "react";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { useNavigate, useParams } from "react-router-dom";
import Navbar from "../../components/navbar/Navbar";
import Sidebar from "../../components/sidebar/Sidebar";
import { userColumns } from "../../resources/employeeDataTable";
import { validateToken } from "../../utils/localStorageHandling";
import GroupsIcon from '@mui/icons-material/Groups';
import "./teamDetails.scss";
import { Link } from "react-router-dom";

function TeamDetails(props) {
  const navigate = useNavigate();

  const[groupName, setGroupName] = useState();
  const [openness, setOpenness] = useState();
  const [conscientiousness, setConscientiousness] = useState();
  const [extraversion, setExtraversion] = useState();
  const [agreeableness, setAgreeableness] = useState();
  const [neuroticism, setNeuroticism] = useState();

  let params = useParams();
  const [userScoresForTeams, setUserScoresForTeams] = useState();

  const actionColumn = [
    {
      field: "action",
      headerName: "Action",
      minWidth: 70,
      flex:0.7,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <div className="cellAction">
            <Link
              to={`/employees/${params.row.id}`}
              style={{ textDecoration: "none" }}
            >
              <div className="viewButton">View</div>
            </Link>
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    validateToken("admin", navigate);
    axios
      .get(
        process.env.REACT_APP_ROOT_URL +
          "/api/v1/person/allEmployeeScoresPerTeam",
        {
          params: {
            companyId: localStorage.getItem("companyId")
              ? localStorage.getItem("companyId")
              : 123,
            teamId: params.teamId ? params.teamId : 1,
          },
          headers: { Authorization: localStorage.getItem("authHeader") },
        }
      )
      .then((response) => {
        setUserScoresForTeams(response.data);
      });
    axios
      .get(
        process.env.REACT_APP_ROOT_URL + "/api/v1/group/groupScoresPerGroup",
        {
          params: {
            companyId: localStorage.getItem("companyId")
              ? localStorage.getItem("companyId")
              : 123,
            groupId: params.teamId ? params.teamId : 1,
          },
          headers: { Authorization: localStorage.getItem("authHeader") },
        }
      )
      .then((response) => {
        console.log(response.data);
        setGroupName(response.data.groupName);
        setOpenness(response.data.openness);
        setConscientiousness(response.data.conscientiousness);
        setExtraversion(response.data.extraversion);
        setAgreeableness(response.data.agreeableness);
        setNeuroticism(response.data.neuroticism);
      });
  }, []);
  return (
    <div class="employee">
      <Navbar type={props.source === "employee" ? "employee" : "admin"} />
      <div className="employeeContainer">
        {props.source !== "employee" && <Sidebar selectedIndex={0} />}
        <div className="contentArea">
          <div className="topProfile">
            <div className="left">
              {/*  <div className="editbutton">Edit</div> */}
              <h1 className="title">Basic Details</h1>
              <div className="item">
                <GroupsIcon className="itemImg" />
                <div className="details">
                  <h1 className="itemTitle">{groupName}</h1>
{/*                   <div className="detailItem">
                    <span className="itemKey">Email:</span>
                    <span className="itemValue">{employeeEmail}</span>
                  </div> */}
                </div>
              </div>
            </div>
            <div className="right">
              <h1 className="title">Team Score</h1>
              <div className="progressBars">
                <div className="progress">
                  <h3 className="progressTitle">Openness</h3>
                  <CircularProgressbar
                    className="progressBar"
                    value={openness * 10}
                    text={openness}
                  />
                </div>
                <div className="progress">
                  <h3 className="progressTitle">Conscientiousness</h3>
                  <CircularProgressbar
                    className="progressBar"
                    value={conscientiousness * 10}
                    text={conscientiousness}
                  />
                </div>
                <div className="progress">
                  <h3 className="progressTitle">Extraversion</h3>
                  <CircularProgressbar
                    className="progressBar"
                    value={extraversion * 10}
                    text={extraversion}
                  />
                </div>
                <div className="progress">
                  <h3 className="progressTitle">Agreeableness</h3>
                  <CircularProgressbar
                    className="progressBar"
                    value={agreeableness * 10}
                    text={agreeableness}
                  />
                </div>
                <div className="progress">
                  <h3 className="progressTitle">Neuroticism</h3>
                  <CircularProgressbar
                    className="progressBar"
                    value={neuroticism * 10}
                    text={neuroticism}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="bottomProfile">
            <h1 className="title">Employee Scores</h1>
            <div className="employeeAssessments">
              <DataGrid
                rows={userScoresForTeams ? userScoresForTeams : []}
                columns={userColumns.concat(actionColumn)}
                pageSize={10}
                rowsPerPageOptions={[10]}
                components={{ Toolbar: GridToolbar }}
                // checkboxSelection
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TeamDetails;
