import {
  Alert,
  Avatar,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Link,
  Paper,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import "./login.scss";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import jwt_decode from "jwt-decode";
import { useEffect } from "react";
import {blue } from '@mui/material/colors';
function Login(props) {
  const paperStyle = {
    padding: 20,
    width: "50vh",
    margin: "40px auto",
  };
  const navigate = useNavigate();
  const btnstyle = { margin: "8px 0" };
  const txtStyle = { margin: "8px 0" };
  const [username, setUsername] = useState();
  const [password, setPassword] = useState();
  const [errorSnackbar, setErrorSnackBar] = useState(false);
  const[errorMessage, setErrorMessage] = useState();

  const handleChangeUserName = (event) => {
    //console.log(event);
    setUsername(event.target.value);
  };
  const handleChangePassword = (event) => {
    //console.log(event);
    setPassword(event.target.value);
  };
  useEffect(() => {
    if (localStorage.getItem("jwt_admin") && props.type === "admin") {
      navigate("/overview");
    } else if (
      (localStorage.getItem("jwt_employee")) &&
      props.type === "employee"
    ) {
      navigate("/myTasks");
    }
  }, []);
  const handleSubmit = (event) => {
    event.preventDefault();

    axios
      .post(process.env.REACT_APP_ROOT_URL+"/login", {
        username,
        password,
      })
      .then(function (response) {
        //console.log(response.headers.authorization);
        const authHeader = response.headers.authorization;
        localStorage.setItem("authHeader", authHeader);
        const jwt = response.headers.authorization.replace("Bearer ", "");
        const jwtData = jwt_decode(jwt);
        console.log(jwtData);
        const authority = jwtData.authorities[0].authority;
        localStorage.setItem("authority", authority);
        if (authority === "ROLE_ADMIN" && props.type === "admin") {
          localStorage.setItem("jwt_admin", jwt);
        }else if(authority === "ROLE_ADMIN" && props.type === "employee"){
          localStorage.setItem("jwt_employee", jwt);
        }
        else if (props.type === "employee") {
          localStorage.setItem("jwt_employee", jwt);
        }
        axios
          .get(process.env.REACT_APP_ROOT_URL+"/api/v1/person/email", {
            params: {
              emailId: jwtData.sub,
            },
            headers: { Authorization: authHeader },
          })
          .then((response) => {
            console.log(response.data);
            if(props.type==="admin"){
            localStorage.setItem("userId", response.data.id);
            localStorage.setItem("adminFirstName", response.data.firstName);
            localStorage.setItem("adminLastName", response.data.lastName);
            localStorage.setItem("adminEmail", response.data.email);

            }else if(props.type==="employee"){
              localStorage.setItem("employeeId", response.data.id);
              localStorage.setItem("employeeFirstName", response.data.firstName);
              localStorage.setItem("employeeLastName", response.data.lastName);
              localStorage.setItem("employeeEmail", response.data.email);
            }
            localStorage.setItem("companyId", response.data.companyId);
            if (props.type === "admin" && authority === "ROLE_ADMIN") {
              navigate("/createAssessment");
            } else if (props.type === "employee") {
              navigate("/myTasks");
            }else{
              setErrorMessage("Invalid Credentials !")
              setErrorSnackBar(true);
            }
          });
      })
      .catch(function (error) {
        if(error.response.status===403 || error.response.status===401){
          setErrorMessage("Invalid Credentials !")
          setErrorSnackBar(true);
        }
        console.log(error);
      });
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setErrorSnackBar(false);
  };
  return (
    <div class="appLogin">
      <Grid>
        <Paper elevation={10} style={paperStyle}>
          <Grid align="center">
            <Avatar sx={{ bgcolor: blue[700] }} >
              <LockOutlinedIcon style={{ color: "white" }} />
            </Avatar>
            <h2>Sign In</h2>
          </Grid>
          <form onSubmit={handleSubmit}>
            <TextField
              label="Username"
              placeholder="Enter Username"
              fullWidth
              required
              style={txtStyle}
              onChange={handleChangeUserName}
            />
            <TextField
              label="Password"
              placeholder="Enter Password"
              type="password"
              fullWidth
              required
              style={txtStyle}
              onChange={handleChangePassword}
            />
{/*             <FormControlLabel
              control={<Checkbox defaultChecked />}
              label="Remember Me"
            /> */}
            <Button
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
              style={btnstyle}
            >
              Sign In
            </Button>
          </form>
          <Typography>
            <Link href="#">Forgot Password</Link>
          </Typography>
          <Typography>
            Don't have an account?
            <Link href="/signup">Sign Up</Link>
          </Typography>
        </Paper>
      </Grid>
      <Snackbar
        open={errorSnackbar}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
          {errorMessage}
        </Alert>
      </Snackbar>
    </div>
  );
}

export default Login;
