import Person4Icon from "@mui/icons-material/Person4";
import { DataGrid ,GridToolbar} from "@mui/x-data-grid";
import axios from "axios";
import { useEffect, useState } from "react";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { useParams } from "react-router-dom";
import Navbar from "../../components/navbar/Navbar";
import Sidebar from "../../components/sidebar/Sidebar";
import {
  employeeAssessmentColumns,
  employeeAssessmentRows
} from "../../resources/employeeAssessments";
import "./employee.scss";

function Employee(props) {

  const[employeeName, setEmployeeName] = useState();
  const[employeeEmail, setEmployeeEmail] = useState();
  const[openness, setOpenness] = useState();
  const[conscientiousness, setConscientiousness] = useState();
  const[extraversion, setExtraversion] = useState();
  const[agreeableness, setAgreeableness] = useState();
  const[neuroticism, setNeuroticism] = useState();
  let profileId ;
  if(props.type==="myProfile"){
    if(props.source==="admin"){
      profileId = localStorage.getItem("userId");
    }
    else if(props.source==="employee"){
      profileId = localStorage.getItem("employeeId")
    }
  }
  let params = useParams();
  const[userAssessmentAggregateScores, setUserAssessmentAggregateScores] = useState();
  useEffect(() => {
    axios.get(process.env.REACT_APP_ROOT_URL+"/api/v1/assessment/userAssessmentAggregateScores",{ params: { 
      assesseeId:  props.type==="myProfile" ? profileId : params.employeeId ? params.employeeId : 123 },
    headers: { Authorization: localStorage.getItem("authHeader") },})
    .then((response) => {
      setUserAssessmentAggregateScores(response.data)
    });

      axios.get(process.env.REACT_APP_ROOT_URL+"/api/v1/person/userAggregateScores",
      { params: { assesseeId:  props.type==="myProfile" ? profileId : params.employeeId ? params.employeeId : 123 },
      headers: { Authorization: localStorage.getItem("authHeader") },})
      .then((response) => {
        console.log(response.data);
        if(props.type==="myProfile"){
          setEmployeeName(props.fullname);
        }else{
        setEmployeeName(response.data[0].assessee.firstName + " " + response.data[0].assessee.lastName);
        }
        if(props.type==="myProfile"){
          setEmployeeEmail(props.email);
        }else{
          setEmployeeEmail(response.data[0].assessee.email);
        }
        
        const scoreMap = new Map();
        scoreMap.set(response.data[0].parameter.parameterName,response.data[0].score);
        scoreMap.set(response.data[1].parameter.parameterName,response.data[1].score);
        scoreMap.set(response.data[2].parameter.parameterName,response.data[2].score);
        scoreMap.set(response.data[3].parameter.parameterName,response.data[3].score);
        scoreMap.set(response.data[4].parameter.parameterName,response.data[4].score);

        setOpenness(scoreMap.get("Openness"));
        setConscientiousness(scoreMap.get("Conscientiousness"));
        setExtraversion(scoreMap.get("Extraversion"));
        setAgreeableness(scoreMap.get("Agreeableness"));
        setNeuroticism(scoreMap.get("Neuroticism"));
      });
  }, []);
  return (
    <div class="employee">
     <Navbar type= {props.source==="employee" ? "employee" : "admin" }/>
      <div className="employeeContainer">
      {props.source!=="employee" && <Sidebar selectedIndex = {1}/> }
        <div className="contentArea">
         
          <div className="topProfile">
            <div className="left">
              {/*  <div className="editbutton">Edit</div> */}
              <h1 className="title">Basic Details</h1>
              <div className="item">
                <Person4Icon className="itemImg" />
                <div className="details">
                  <h1 className="itemTitle">{employeeName}</h1>
                  <div className="detailItem">
                    <span className="itemKey">Email:</span>
                    <span className="itemValue">
                      {employeeEmail}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="right">
              <h1 className="title">Aggregate Scores</h1>
              <div className="progressBars">
                <div className="progress">
                  <h3 className="progressTitle">Openness</h3>
                  <CircularProgressbar
                    className="progressBar"
                    value={openness*10}
                    text={openness}
                  />
                </div>
                <div className="progress">
                  <h3 className="progressTitle">Conscientiousness</h3>
                  <CircularProgressbar
                    className="progressBar"
                    value={conscientiousness*10}
                    text={conscientiousness}
                  />
                </div>
                <div className="progress">
                  <h3 className="progressTitle">Extraversion</h3>
                  <CircularProgressbar
                    className="progressBar"
                    value={extraversion*10}
                    text={extraversion}
                  />
                </div>
                <div className="progress">
                  <h3 className="progressTitle">Agreeableness</h3>
                  <CircularProgressbar
                    className="progressBar"
                    value={agreeableness*10}
                    text={agreeableness}
                  />
                </div>
                <div className="progress">
                  <h3 className="progressTitle">Neuroticism</h3>
                  <CircularProgressbar
                    className="progressBar"
                    value={neuroticism*10}
                    text={neuroticism}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="bottomProfile">
            <h1 className="title">Assessment Scores</h1>
            <div className="employeeAssessments">
              <DataGrid
                rows={userAssessmentAggregateScores ? userAssessmentAggregateScores : []}
                columns={employeeAssessmentColumns}
                pageSize={10}
                rowsPerPageOptions={[10]}
                components={{ Toolbar: GridToolbar }}
                // checkboxSelection
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Employee;
