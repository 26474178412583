import React from "react";
import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Experiment1 from "./components/experiments/Experiment1";
import Experiment2 from "./components/experiments/Experiment2";
import Home from "./pages/home/Home";
import Registration from "./pages/registration/Registration";
import Login from "./pages/login/Login";
import EmployeeList from "./pages/employeeList/EmployeeList";
import Employee from "./pages/employee/Employee";
import CreateAssessment from "./pages/createAssessment/CreateAssessment";
import AssignAssessment from "./pages/assignAssessment/AssignAssessment";
import AssessmentList from "./pages/assessmentList/AssessmentList";
import AssessmentDetail from "./pages/assessmentDetail/AssessmentDetail";
import Task from "./components/task/Task";
import TaskPage from "./pages/taskPage/TaskPage";
import TestPage from "./pages/testPage/TestPage";
import TestTimer from "./components/timer/TestTimer";
import AssessmentGroup from "./components/group/AssessmentGroup";
import CreateGroup from "./pages/createGroup/CreateGroup";
import EditGroup from "./pages/editGroup/EditGroup";
import SidebarNew from "./components/sidebar/SidebarNew";
import AdminLogin from "./pages/adminLogin/AdminLogin";
import EmployeeLogin from "./pages/employeeLogin/EmployeeLogin";
import PrivateRoutesAdmin from "./utils/PrivateRoutesAdmin";
import PrivateRoutesEmployee from "./utils/PrivateRoutesEmployee";
import AssignAssessmentTeam from "./pages/assignAssessmentTeam/AssignAssessmentTeam";
import HomePage from "./pages/homePage/HomePage";
import MyProfile from "./pages/myProfile/EmployeeProfile";
import ContactUs from "./pages/contactUs/ContactUs";
import ClockTimer from "./pages/chatgpt/ClockTimer";
import EmployeeProfile from "./pages/myProfile/EmployeeProfile";
import AdminProfile from "./pages/adminProfile/adminProfile";
import TaskPageGrid from "./pages/taskPageGrid/TaskPageGrid";
import EmailVerification from "./pages/emailVerification/EmailVerification";
import TeamDetails from "./pages/teamDetails/TeamDetails";


class App extends React.Component {
  /*    createRoute = (props)=> {
    return <Route path={props.path} element={{props.component}} />;
  } */
  render() {
    return (
      <BrowserRouter>
        <Routes>
        <Route exact path="/" element={<HomePage />} />
          <Route element={<PrivateRoutesAdmin />}>
            <Route path="/overview" element={<Home />} />
            <Route path="/createAssessment" element={<CreateAssessment />} />
            <Route path="/assignAssessment" element={<AssignAssessment />} />
            <Route path="/assignAssessmentTeam" element={<AssignAssessmentTeam />} />
            <Route path="/createTeam" element={<CreateGroup />} />
            <Route path="/editTeam" element={<EditGroup />} />
            <Route path="/adminProfile" element={<AdminProfile />} />
            <Route path="/assessments">
              <Route index element={<AssessmentList />} />
              <Route path=":assessmentId" element={<AssessmentDetail />} />
            </Route>
            <Route path="employees">
              <Route index element={<EmployeeList />} />
              <Route path=":employeeId" element={<Employee />} />
            </Route>
            <Route path="teams">
              <Route index element={<EmployeeList />} />
              <Route path=":teamId" element={<TeamDetails />} />
            </Route>
          </Route>
          <Route element={<PrivateRoutesEmployee />}>
            <Route path="/myTasks" element={<TaskPageGrid />} />
            <Route path="/test" element={<TestPage />} />
            <Route path="/employeeProfile" element={<EmployeeProfile />} />
          </Route>
          {/*   <Route path="login" element={<Login />} /> */}
          <Route path="registration" element={<Registration />} >
              <Route index element={<Registration />} />
              <Route path=":companyCode" element={<Registration />} />
          </Route>
          <Route path="/Experiment1" element={<Experiment1 />} />
          <Route path="/Experiment2" element={<Experiment2 />} />

          {/*          <Route path="/login" element={<Login />} /> */}
          <Route path="/adminLogin" element={<AdminLogin />} />
          <Route path="/employeeLogin" element={<EmployeeLogin />} />
          <Route path="/signup" element={<Registration />} />
          <Route path="/task" element={<Task />} />

          <Route path="/timer" element={<TestTimer />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/book-a-demo" element={<ContactUs />} />
          <Route path="/groupCard" element={<AssessmentGroup />} />

          <Route path="/sidebarNew" element={<SidebarNew />} />
          <Route path="/clock" element={<ClockTimer />} />
          <Route path="/taskPageGrid" element={<TaskPageGrid />} />
          <Route path="/emailverification/:token" element={<EmailVerification />} />
        </Routes>
      </BrowserRouter>
    );
  }
}
export default App;
