import React, { useState, useEffect } from "react";
import './clocktimer.scss'

function ClockTimer() {
  const [time, setTime] = useState(0);

  useEffect(() => {
    let interval = setInterval(() => {
      setTime(time => time + 1);
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  let hours = Math.floor(time / 3600);
  let minutes = Math.floor((time % 3600) / 60);
  let seconds = time % 60;

  let hourRotation = 30 * hours + 0.5 * minutes;
  let minuteRotation = 6 * minutes + 0.1 * seconds;
  let secondRotation = 6 * seconds;

  return (
    <div>
      <h1>Timer: {time}</h1>
      <div className="clock-layout">
        <div className="clock-face">
          <div
            className="hand hour-hand"
            style={{ transform: `rotate(${hourRotation}deg)` }}
          ></div>
          <div
            className="hand minute-hand"
            style={{ transform: `rotate(${minuteRotation}deg)` }}
          ></div>
          <div
            className="hand second-hand"
            style={{ transform: `rotate(${secondRotation}deg)` }}
          ></div>
        </div>
      </div>
    </div>
  );
}

export default ClockTimer;
