import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { CircularProgressbar } from "react-circular-progressbar";
import Navbar from "../../components/navbar/Navbar";
import Sidebar from "../../components/sidebar/Sidebar";
import { groupScoreColumns } from "../../resources/groupScores";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie } from "react-chartjs-2";
import "./homePage.scss";
import HomeNavbar from "../../components/homeNavbar/HomeNavbar";
import HomeComponent from "../../components/homecomponent/HomeComponent";
import myhome from "./myhome.svg";
import ProcessComponent from "../../components/processcomponent/ProcessComponent";
import BenefitsComponent from "../../components/benefitsComponent/BenefitsComponent";
import Footer from "../../components/footerComponent/Footer";

function HomePage(props) {
  ChartJS.register(ArcElement, Tooltip, Legend);

  useEffect(() => {}, []);
  return (
    <div className="home">
      <HomeNavbar /> {/*  <img src = {myhome} alt="My Happy SVG"/> */}
      {/*   <object data="myhome.svg" width="300" height="300"> </object> */}
      <section id="homeComp"><HomeComponent /></section>
      <section id="processComp"><ProcessComponent /></section>
      
      <section id="benefitsComp"><BenefitsComponent /></section>
      <section id="footerComp"><Footer /></section>
    </div>
  );
}

export default HomePage;
