import { Alert, Avatar, Button, Checkbox, FormControlLabel, Grid, Paper, Snackbar, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import "./registration.scss";
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';
import axios from "axios";
import { blue } from "@mui/material/colors";
import { useNavigate, useParams } from "react-router-dom";
import { RepeatOneSharp } from "@mui/icons-material";
import meesho from "./meesho.png";
import appraized from "./logo.png";

function Registration(props) {
  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();
  const [email, setEmail] = useState();
  const [phoneNumber, setPhoneNumber] = useState();
  const [password, setPassword] = useState();
  const [confirmPassword, setConfirmPassword] = useState();
  const [errorSnackbar, setErrorSnackBar] = useState(false);
  const[errorMessage, setErrorMessage] = useState();
  const [successSnackBar, setSuccessSnackBar] = useState(false);
  const[isChecked, setIsChecked] = useState(false);
  let { companyCode } = useParams();
  const navigate = useNavigate();
 
  useEffect(() => {
    console.log(companyCode);
      axios
      .get(process.env.REACT_APP_ROOT_URL+"/api/v1/company/companyCode", {
        params: {
          companyCode,
        },
      })
      .then((response) => {
       // console.log(response);
        if(response.status!=200){
          navigate("/");
        }
  
      });
  },[companyCode]);

  const handleChangeFirstname = (event) => {
    //console.log(event);
    setFirstName(event.target.value);
  };
  const handleChangeLastname = (event) => {
    //console.log(event);
    setLastName(event.target.value);
  };
  const handleChangeEmail = (event) => {
    //console.log(event);
    setEmail(event.target.value);
  };
  const handleChangePhoneNumber = (event) => {
    //console.log(event);
    setPhoneNumber(event.target.value);
  };
  const handleChangePassword = (event) => {
    //console.log(event);
    setPassword(event.target.value);
  };
  const handleChangeConfirmPassword = (event) => {
    //console.log(event);
    setConfirmPassword(event.target.value);
  };
  const clearForm =() => {
    setFirstName("");
    setLastName("");
    setEmail("");
    setPhoneNumber("");
    setPassword("");
    setConfirmPassword("");
    setIsChecked(false);
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    axios
      .post(process.env.REACT_APP_ROOT_URL+"/api/v1/registration/employee", {
        firstName,
        lastName,
        email,
        password,
        phoneNumber,
        companyCode
      }).then(function (response){
        setSuccessSnackBar(true);
        console.log(response);
        clearForm();
      })
      .catch(function (error) {
        console.log(error.response.data.message);
        setErrorMessage(error.response.data.message)
              setErrorSnackBar(true);
            //  clearForm();
      });
  }
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setErrorSnackBar(false);
    setSuccessSnackBar(false);
  };

  const handleIsChecked = (event) => {
    setIsChecked(event.target.checked);
  }
  return (
    <div>
      <Grid>
        <Paper elevation={20} className="registrationPaper">
          <Grid align = 'center'>
          <img
            className="companyLogo"
            src={appraized}
            alt="Logo"
          />
            {/* <Avatar className="registrationAvatar"  sx={{ bgcolor: blue[700] }} > */}
            {/* <AddCircleOutlineRoundedIcon  style={{ color: "white" }} /> */}
            {/* </Avatar> */}
            <h2>Sign Up</h2>
            <Typography variant="caption">Please fill this form to create an account</Typography>
          </Grid>
          <form onSubmit={handleSubmit}>
            <TextField required className="registrationText" fullWidth label ="First Name" onChange={handleChangeFirstname} value={firstName} />
            <TextField required className="registrationText" fullWidth label ="Last Name" onChange={handleChangeLastname} value={lastName} />
            <TextField required type="email" className="registrationText" fullWidth label ="Email" onChange={handleChangeEmail} value={email} />
            <TextField required type="number" className="registrationText" fullWidth label ="Phone Number" onChange={handleChangePhoneNumber} value= {phoneNumber}/>
            <TextField required type="password" className="registrationText" fullWidth label ="Password" onChange={handleChangePassword} value={password}/>
            <TextField required type="password" className="registrationText" fullWidth label ="Confirm Password" onChange={handleChangeConfirmPassword} value={confirmPassword}/>
            <FormControlLabel
            control={<Checkbox required checked = {isChecked} onClick={handleIsChecked} />}
            label="I accept the Terms and Conditions"
          />
            <Button fullWidth type="submit" variant="contained" color="primary">Sign Up</Button>
          </form>
        </Paper>
      </Grid>
      <Snackbar
        open={successSnackBar}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert onClose={handleClose} severity="success" sx={{ width: "100%" }}>
         Registration Successfull. Please check your email for further instructions
        </Alert>
      </Snackbar>
      <Snackbar
        open={errorSnackbar}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
         {errorMessage}
        </Alert>
      </Snackbar>
    </div>
  );
}

export default Registration;
