import React from "react";
import { Grid, TextField, Button, Snackbar, Alert } from "@mui/material";
import "./contactus.scss";
import HomeNavbar from "../../components/homeNavbar/HomeNavbar";
import { useEffect } from "react";
import { useState } from "react";
import axios from "axios";


function ContactUs(props) {
const[name, setName] = useState();
const[email, setEmail] = useState();
const[company, setCompany] = useState();
const[message, setMessage] = useState();
const [errorSnackbar, setErrorSnackBar] = useState(false);
const[errorMessage, setErrorMessage] = useState();
const [successSnackBar, setSuccessSnackBar] = useState(false);
const handleChangeName = (event) => {
  //console.log(event);
  setName(event.target.value);
};
const handleChangeEmail = (event) => {
  //console.log(event);
  setEmail(event.target.value);
};
const handleChangeCompany = (event) => {
  //console.log(event);
  setCompany(event.target.value);
};
const handleChangeMessage = (event) => {
  //console.log(event);
  setMessage(event.target.value);
};

const clearForm =() => {
  setName("");
  setEmail("");
  setCompany("");
  setMessage("");
}
const handleSubmit = (event) => {
  event.preventDefault();
  axios
    .post(process.env.REACT_APP_ROOT_URL+"/api/v1/registration/sendContactUsEmail", {
      type:props.title,
      name,
      email,
      company,
      message
      
    }).then(function (response){
      setSuccessSnackBar(true);
      console.log(response);
      clearForm();
    })
    .catch(function (error) {
      console.log(error.response.data.message);
      setErrorMessage("Unable to Send Email.Please contact supprot")
            setErrorSnackBar(true);
          //  clearForm();
    });
}
const handleClose = (event, reason) => {
  if (reason === "clickaway") {
    return;
  }
  setErrorSnackBar(false);
  setSuccessSnackBar(false);
};
  return (
    <div>
      {/* <HomeNavbar /> */}
      
      <Grid container spacing={3} className="contact-us">
      <form onSubmit={handleSubmit}>
        <Grid item xs={12}>
          <h2>{props.title}</h2>
        </Grid>
        <Grid item xs={12}>
          <TextField required label="Name" fullWidth margin="normal" onChange={handleChangeName}value={name} />
        </Grid>
        <Grid item xs={12}>
          <TextField required label="Email" fullWidth margin="normal" onChange={handleChangeEmail}value={email}  />
        </Grid>
        <Grid item xs={12}>
          <TextField required label="Company" fullWidth margin="normal" onChange={handleChangeCompany}value={company}  />
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            label="Message"
            fullWidth
            margin="normal"
            multiline
            rows={5}
            onChange={handleChangeMessage}
            value={message} 
          />
        </Grid>
        <Grid item xs={12}>
          <Button variant="contained" color="primary" type="submit">
            Send
          </Button>
        </Grid>
        </form>
      </Grid>
      <Snackbar
        open={successSnackBar}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert onClose={handleClose} severity="success" sx={{ width: "100%" }}>
         Email sent successfully.We will revert to you shortly!
        </Alert>
      </Snackbar>
      <Snackbar
        open={errorSnackbar}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
         {errorMessage}
        </Alert>
      </Snackbar>

      {/* <style jsx>{`
        .contact-us {
          display: grid;
          grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
          grid-gap: 20px;
        }

        @media (min-width: 960px) {
          .contact-us {
            grid-template-columns: repeat(1, 1fr);
          }
        }
      `}</style> */}
    </div>
  );
}

export default ContactUs;
