import { DataGrid,GridToolbar  } from "@mui/x-data-grid";
import { userColumns, userRows } from "../../resources/employeeDataTable";
import "./employeeListDataTable.scss";
import { Link } from "react-router-dom";

function EmployeeListDataTable(props) {
  const actionColumn = [
    {
      field: "action",
      headerName: "Action",
      minWidth: 120,
      flex:1.2,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <div className="cellAction">
            <Link
              to={`/employees/${params.row.id}`}
              style={{ textDecoration: "none" }}
            >
              <div className="viewButton">View Profile</div>
            </Link>
          </div>
        );
      },
    },
  ];
  return (
    <div className="myDataTable">
      <DataGrid
        rows={
          props.employeesAssessmentData ? props.employeesAssessmentData : []
        }
        columns={userColumns.concat(actionColumn)}
        pageSize={10}
        rowsPerPageOptions={[10]}
        components={{ Toolbar: GridToolbar }}
        // checkboxSelection
      />
    </div>
  );
}

export default EmployeeListDataTable;
