export const groupScoreColumns = [
  {
    field: "groupName",
    headerName: "Team Name",
    minWidth: 160,
    flex: 1.6,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "openness",
    headerName: "Openness",
    type: "number",
    minWidth: 80,
    flex: 0.8,
    valueGetter: (params) => params.row.openness.toFixed(1),
    align: "center",
    headerAlign: "center",
  },
  {
    field: "conscientiousness",
    headerName: "Conscientiousness",
    type: "number",
    minWidth: 140,
    flex: 1.4,
    valueGetter: (params) => params.row.conscientiousness.toFixed(1),
    align: "center",
    headerAlign: "center",
  },
  {
    field: "extraversion",
    headerName: "Extraversion",
    type: "number",
    minWidth: 100,
    flex: 1,
    valueGetter: (params) => params.row.extraversion.toFixed(1),
    align: "center",
    headerAlign: "center",
  },
  {
    field: "agreeableness",
    headerName: "Agreeableness",
    type: "number",
    minWidth: 110,
    flex: 1.1,
    valueGetter: (params) => params.row.agreeableness.toFixed(1),
    align: "center",
    headerAlign: "center",
  },
  {
    field: "neuroticism",
    headerName: "Neuroticism",
    type: "number",
    minWidth: 100,
    flex: 1,
    valueGetter: (params) => params.row.neuroticism.toFixed(1),
    align: "center",
    headerAlign: "center",
  },
];
