import { DataGrid,GridToolbar } from "@mui/x-data-grid";
import axios from "axios";
import { ArcElement, Chart as ChartJS, Legend, Tooltip } from 'chart.js';
import React, { useEffect, useState } from "react";
import { Pie } from 'react-chartjs-2';
import { CircularProgressbar } from "react-circular-progressbar";
import { useNavigate } from "react-router-dom";
import Navbar from "../../components/navbar/Navbar";
import Sidebar from "../../components/sidebar/Sidebar";
import { groupScoreColumns } from "../../resources/groupScores";
import { validateToken } from "../../utils/localStorageHandling";
import "./home.scss";
import { Link } from "react-router-dom";

function Home(props) {
  const [openness, setOpenness] = useState();
  const [conscientiousness, setConscientiousness] = useState();
  const [extraversion, setExtraversion] = useState();
  const [agreeableness, setAgreeableness] = useState();
  const [neuroticism, setNeuroticism] = useState();
  const[groupAggregateScores, setGroupAggregateScores] = useState();
  const[completed , setCompleted] = useState();
  const[pending, setPending] = useState();
  ChartJS.register(ArcElement, Tooltip, Legend);
  const navigate = useNavigate();
  const actionColumn = [
    {
      field: "action",
      headerName: "Action",
      minWidth: 70,
      flex:0.7,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <div className="cellAction">
            <Link
              to={`/teams/${params.row.id}`}
              style={{ textDecoration: "none" }}
            >
              <div className="viewButton">View</div>
            </Link>
          </div>
        );
      },
    },
  ];
  const data = {
    labels: ['Completed','Pending'],
    datasets: [
      {
        label: 'Tasks',
        data: [completed, pending],
        backgroundColor: [
          'rgba(75, 192, 192, 0.2)',
          'rgba(255, 99, 132, 0.2)'       
        ],
        borderColor: [
          'rgba(75, 192, 192, 1)',
          'rgba(255, 99, 132, 1)'
          
        ],
        borderWidth: 1,
      },
    ],
  };
  const options = {
    legend: {
      position: "left",
      display: false,
      align: "left"
    },
  };
  
  useEffect(() => {
    validateToken("admin", navigate);
    axios.get(process.env.REACT_APP_ROOT_URL+"/api/v1/assessment/companyAggregate",{ params: { companyId:  localStorage.getItem("companyId") ? localStorage.getItem("companyId") : 123 },headers: { Authorization: localStorage.getItem("authHeader")}})
    .then((response) => {
      console.log(response.data);
      setOpenness(response.data[1]);
      setConscientiousness(response.data[2]);
      setExtraversion(response.data[3]);
      setAgreeableness(response.data[4]);
      setNeuroticism(response.data[5]);
    });
    axios.get(process.env.REACT_APP_ROOT_URL+"/api/v1/group/groupScores",{ params: { companyId:  localStorage.getItem("companyId") ? localStorage.getItem("companyId") : 123 },headers: { Authorization: localStorage.getItem("authHeader")}})
    .then((response) => {
      console.log(response.data);
      setGroupAggregateScores(response.data);
    });
    axios.get(process.env.REACT_APP_ROOT_URL+"/api/v1/assessment/tasks/status",{ params: { companyId:  localStorage.getItem("companyId") ? localStorage.getItem("companyId") : 123 },headers: { Authorization: localStorage.getItem("authHeader")}})
    .then((response) => {
      console.log(response.data);
      setCompleted(response.data.completed);
      setPending(response.data.pending);
    });

  },[]);
  return (
    <div className="home">
      <Navbar />
      <div className="homeContainer">
        <Sidebar selectedIndex={0} />
        <div className="contentArea">
          <div className="topProfile">
            <div className="left">
            <h1 className="title">Tasks Summary</h1>
            <Pie data={data} options = {options} />
            </div>
            <div className="right">
              <h1 className="title">Organisation Scores</h1>
              <div className="progressBars">
                <div className="progress">
                  <h3 className="progressTitle">Openness</h3>
                  <CircularProgressbar
                    className="progressBar"
                    value={openness * 10}
                    text={openness}
                  />
                </div>
                <div className="progress">
                  <h3 className="progressTitle">Conscientiousness</h3>
                  <CircularProgressbar
                    className="progressBar"
                    value={conscientiousness * 10}
                    text={conscientiousness}
                  />
                </div>
                <div className="progress">
                  <h3 className="progressTitle">Extraversion</h3>
                  <CircularProgressbar
                    className="progressBar"
                    value={extraversion * 10}
                    text={extraversion}
                  />
                </div>
                <div className="progress">
                  <h3 className="progressTitle">Agreeableness</h3>
                  <CircularProgressbar
                    className="progressBar"
                    value={agreeableness * 10}
                    text={agreeableness}
                  />
                </div>
                <div className="progress">
                  <h3 className="progressTitle">Neuroticism</h3>
                  <CircularProgressbar
                    className="progressBar"
                    value={neuroticism * 10}
                    text={neuroticism}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="bottomProfile">
            <h1 className="title">Team Scores</h1>
            <div className="employeeAssessments">
              <DataGrid
                rows={groupAggregateScores ? groupAggregateScores : []}
                columns={groupScoreColumns.concat(actionColumn)}
                pageSize={10}
                rowsPerPageOptions={[10]}
                components={{ Toolbar: GridToolbar }}
                // checkboxSelection
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
