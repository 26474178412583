import { DataGrid,GridToolbar} from "@mui/x-data-grid";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Assessment from "../../components/asseessmentcard/Assessment";
import Navbar from "../../components/navbar/Navbar";
import Sidebar from "../../components/sidebar/Sidebar";
import jwtDecode from "jwt-decode";
import {
  employeeScoresColumns,
  employeeScoresRows,
} from "../../resources/employeeScores";
import "./assessmentDetail.scss";
import { Backdrop, Fade, Modal } from "@mui/material";
import { Box } from "@mui/system";
import ContactUs from "../contactUs/ContactUs";
import TaskCompletionStatus from "../taskCompletionStatus/TaskCompletionStatus";

function AssessmentDetail(props) {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    //width: 400,
    bgcolor: "white",
    //border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };
  let params = useParams();
  const [assessmentScoreData, setAssessmentScoreData] = useState();
  const [openModal, setOpenModal] = useState(false);
  const [assesseeId, setAssesseeId] = useState();
  const taskCompletionPopup = (event,params) => {
    event.preventDefault();
    setAssesseeId(params.row.assesseeId);
    setOpenModal(true);
  };
  const handleClose = () => setOpenModal(false);
  const employeeScoresColumns = [
    {
      field: "firstName",
      headerName: "First Name",
      minWidth: 120,
      flex: 1.2,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "lastName",
      headerName: "Last Name",
      minWidth: 120,
      flex: 1.2,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "employeeEmail",
      headerName: "Employee Email",
      type: "date",
      minWidth: 210,
      flex: 2.1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "tasksCompleted",
      headerName: "Tasks Completed",
      minWidth: 130,
      flex: 1.3,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => (
        <a
          href=""
          onClick={(e) => {
            taskCompletionPopup(e, params);
          }}
        >
          {params.row.completedTasks}/{params.row.assignedTasks}
        </a>
      ),
    },
    {
      field: "openness",
      headerName: "Openness",
      type: "number",
      minWidth: 80,
      flex: 0.8,
      align: "center",
      headerAlign: "center",
      valueGetter: (params) => params.row.openness.toFixed(1),
    },
    {
      field: "conscientiousness",
      headerName: "Conscientiousness",
      type: "number",
      minWidth: 140,
      flex: 1.4,
      align: "center",
      headerAlign: "center",
      valueGetter: (params) => params.row.conscientiousness.toFixed(1),
    },
    {
      field: "extraversion",
      headerName: "Extraversion",
      type: "number",
      minWidth: 100,
      flex: 1,
      align: "center",
      headerAlign: "center",
      valueGetter: (params) => params.row.extraversion.toFixed(1),
    },
    {
      field: "agreeableness",
      headerName: "Agreeableness",
      type: "number",
      minWidth: 110,
      flex: 1.1,
      align: "center",
      headerAlign: "center",
      valueGetter: (params) => params.row.agreeableness.toFixed(1),
    },
    {
      field: "neuroticism",
      headerName: "Neuroticism",
      type: "number",
      minWidth: 100,
      flex: 1,
      align: "center",
      headerAlign: "center",
      valueGetter: (params) => params.row.neuroticism.toFixed(1),
    },
  ];
     
  useEffect(() => {
    const token = localStorage.getItem("jwt_admin");
    const decodedToken = jwtDecode(token);
    const expirationTime = decodedToken.exp * 1000;
    const currentTime = new Date().getTime();
    if (currentTime > expirationTime) {
      localStorage.removeItem("jwt_admin");
    }
    axios
      .get(
        process.env.REACT_APP_ROOT_URL +
          "/api/v1/assessment/aggregateAssessmentScores",
        {
          params: {
            assessmentId: params.assessmentId ? params.assessmentId : 123,
          },
          headers: { Authorization: localStorage.getItem("authHeader") },
        }
      )
      .then((response) => {
        setAssessmentScoreData(response.data);
      });
  }, []);
  console.log(params);
  return (
    <div className="assessmentDetail">
      <Navbar />
      <div className="newContainer">
        <Sidebar selectedIndex={5} />
        <div className="contentArea">
          <div className="topAssessmentDetail">
            <h1>Assessment Detail</h1>
          </div>
          <Assessment params={params} type="view" />
          <div className="bottomAssessmentDetail">
            <h1 className="title">Employee Scores</h1>
            <div className="employeeAssessments">
              <DataGrid
                rows={assessmentScoreData ? assessmentScoreData : []}
                columns={employeeScoresColumns}
                pageSize={10}
                rowsPerPageOptions={[10]}
                components={{ Toolbar: GridToolbar }}
                // checkboxSelection
              />
                          <Modal
              aria-labelledby="transition-modal-title"
              aria-describedby="transition-modal-description"
              open={openModal}
              onClose={handleClose}
              closeAfterTransition
              BackdropComponent={Backdrop}
              BackdropProps={{
                timeout: 500,
              }}
            >
              <Fade in={openModal}>
                <Box sx={style}>
                  <TaskCompletionStatus assesseeId= {assesseeId} assessmentId={params.assessmentId}/>
                </Box>
              </Fade>
            </Modal>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AssessmentDetail;
