import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import AssessmentIcon from "@mui/icons-material/Assessment";
import DashboardIcon from "@mui/icons-material/Dashboard";
import GroupsOutlinedIcon from "@mui/icons-material/GroupsOutlined";
import ManageSearchIcon from "@mui/icons-material/ManageSearch";
import PeopleIcon from "@mui/icons-material/People";
import {
  Collapse,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
} from "@mui/material";
import { useEffect } from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import "./sidebar.scss";
import { palette } from "@mui/system";
import { StarBorder } from "@mui/icons-material";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import AddTaskIcon from "@mui/icons-material/AddTask";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import PersonIcon from "@mui/icons-material/Person";
import Diversity3Icon from "@mui/icons-material/Diversity3";

function Sidebar(props) {
  const [selectedIndex, setSelectedIndex] = useState(props.selectedIndex);
  const [open, setOpen] = useState(
    JSON.parse(localStorage.getItem("openStatus"))
      ? JSON.parse(localStorage.getItem("openStatus"))
      : false
  );

  useEffect(() => {
    if (localStorage.getItem("sidebarIndex")) {
      setSelectedIndex(8);
      localStorage.removeItem("sidebarIndex");
    }
  }, [selectedIndex]);

  const handleListItemClick = (event, index) => {
    setSelectedIndex(index);
  };
  const handleClick = () => {
    const val = JSON.parse(localStorage.getItem("openStatus"));
    localStorage.setItem("openStatus", !val);
    setOpen(!open);
  };

  return (
    <div className="mainSidebar">
      <List component="nav" aria-label="main mailbox folders">
        <ListSubheader>DASHBOARD</ListSubheader>
        <Link to="/overview" style={{ textDecoration: "none" }}>
          <ListItemButton
            selected={selectedIndex === 0}
            onClick={(event) => handleListItemClick(event, 0)}
          >
            <ListItemIcon
              style={{ minWidth: "36px" }}
              className="listTextColor"
            >
              <DashboardIcon />
            </ListItemIcon>
            <ListItemText primary="Overview" className="listTextColor" />
          </ListItemButton>
        </Link>
        <ListSubheader>EMPLOYEE</ListSubheader>
        <Link to="/employees" style={{ textDecoration: "none" }}>
          <ListItemButton
            selected={selectedIndex === 1}
            onClick={(event) => handleListItemClick(event, 1)}
          >
            <ListItemIcon
              style={{ minWidth: "36px" }}
              className="listTextColor"
            >
              <PeopleIcon />
            </ListItemIcon>
            <ListItemText primary="Employee Scores" className="listTextColor" />
          </ListItemButton>
        </Link>
        <ListSubheader>ASSESSMENTS</ListSubheader>
        <Link to="/createAssessment" style={{ textDecoration: "none" }}>
          <ListItemButton
            selected={selectedIndex === 2}
            onClick={(event) => handleListItemClick(event, 2)}
          >
            <ListItemIcon
              style={{ minWidth: "36px" }}
              className="listTextColor"
            >
              <AssessmentIcon />
            </ListItemIcon>
            <ListItemText
              primary="Create Assessment"
              className="listTextColor"
            />
          </ListItemButton>
        </Link>
        {/*         <Link to="/assignAssessment" style={{ textDecoration: "none" }}>
        <ListItemButton
          selected={selectedIndex ===3}
          onClick={(event) => handleListItemClick(event, 3)}
        >
          <ListItemIcon style = {{minWidth: '36px'}} className="listTextColor" >
            <ArrowForwardIcon />
          </ListItemIcon >
          <ListItemText primary="Assign Assessment" className="listTextColor"/>
        </ListItemButton>
        </Link>
        <Link to="/assignAssessmentTeam" style={{ textDecoration: "none" }}>
        <ListItemButton
          selected={selectedIndex === 4}
          onClick={(event) => handleListItemClick(event, 4)}
        >
          <ListItemIcon style = {{minWidth: '36px'}} className="listTextColor">
            <ArrowForwardIcon />
          </ListItemIcon>
          <ListItemText primary="Assign Assessment (Team)" className="listTextColor"/>
        </ListItemButton>
        </Link> */}
        <ListItemButton onClick={handleClick}>
          <ListItemIcon style={{ minWidth: "36px" }} className="listTextColor">
            <AddTaskIcon />
          </ListItemIcon>
          <ListItemText primary="Assign Assessment" />
          {open ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse
          in={open}
          timeout="auto"
          unmountOnExit
        >
          <List component="div" disablePadding>
            <Link to="/assignAssessment" style={{ textDecoration: "none" }}>
              <ListItemButton
                sx={{ pl: 4 }}
                selected={selectedIndex === 3}
                onClick={(event) => handleListItemClick(event, 3)}
              >
                <ListItemIcon
                  style={{ minWidth: "36px" }}
                  className="listTextColor"
                >
                  <PersonIcon />
                </ListItemIcon>
                <ListItemText primary="Employee" className="listTextColor" />
              </ListItemButton>
            </Link>
            <Link to="/assignAssessmentTeam" style={{ textDecoration: "none" }}>
              <ListItemButton
                sx={{ pl: 4 }}
                selected={selectedIndex === 4}
                onClick={(event) => handleListItemClick(event, 4)}
              >
                <ListItemIcon
                  style={{ minWidth: "36px" }}
                  className="listTextColor"
                >
                  <Diversity3Icon />
                </ListItemIcon>
                <ListItemText primary="Team" className="listTextColor" />
              </ListItemButton>
            </Link>
          </List>
        </Collapse>
        <Link to="/assessments" style={{ textDecoration: "none" }}>
          <ListItemButton
            selected={selectedIndex === 5}
            onClick={(event) => handleListItemClick(event, 5)}
          >
            <ListItemIcon
              style={{ minWidth: "36px" }}
              className="listTextColor"
            >
              <ManageSearchIcon />
            </ListItemIcon>
            <ListItemText
              primary="Search Assessments"
              className="listTextColor"
            />
          </ListItemButton>
        </Link>
        <ListSubheader>TEAMS</ListSubheader>
        <Link to="/createTeam" style={{ textDecoration: "none" }}>
          <ListItemButton
            selected={selectedIndex === 6}
            onClick={(event) => handleListItemClick(event, 6)}
          >
            <ListItemIcon
              style={{ minWidth: "36px" }}
              className="listTextColor"
            >
              <GroupsOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary="Create Team" className="listTextColor" />
          </ListItemButton>
        </Link>
        <Link to="/editTeam" style={{ textDecoration: "none" }}>
          <ListItemButton
            selected={selectedIndex === 7}
            onClick={(event) => handleListItemClick(event, 7)}
          >
            <ListItemIcon
              style={{ minWidth: "36px" }}
              className="listTextColor"
            >
              <GroupsOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary="Edit Team" className="listTextColor" />
          </ListItemButton>
        </Link>
      </List>
    </div>
  );
}

export default Sidebar;
