export const answerOptions =
  {
    "answerOptions": [
      { "answerText": "Strongly Agree" , "score": 5},
      { "answerText": "Agree" , "score": 4 },
      { "answerText": "Neither Agree nor Disagree" , "score": 3 },
      { "answerText": "Disagree" , "score": 2 },
      { "answerText": "Strongly Disagree" , "score": 1 }
    ]
  }

