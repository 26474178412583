import React from "react";
import AssessmentGroup from "../../components/group/AssessmentGroup";
import Navbar from "../../components/navbar/Navbar";
import Sidebar from "../../components/sidebar/Sidebar";
import "./editGroup.scss";

function EditGroup(props) {
  return (
    <div className="editGroup">
      <Navbar />
      <div className="newContainer">
        <Sidebar selectedIndex={7} />
        <div
          className="contentArea"
          style={{ width: "40%", minWidth: "500px" }}
        >
          <div className="topEditGroup">
            <h1>Edit Team</h1>
          </div>
          <AssessmentGroup type="edit" />
        </div>
      </div>
    </div>
  );
}

export default EditGroup;
