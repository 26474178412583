import jwtDecode from "jwt-decode";

export const validateToken = (type, navigate) => {
  if (type === "admin") {
    const token = localStorage.getItem("jwt_admin");
    if (!token) {
      clearStorage("admin");
      navigate("/adminLogin");
    }
    const decodedToken = jwtDecode(token);
    const expirationTime = decodedToken.exp * 1000;
    const currentTime = new Date().getTime();
    if (currentTime > expirationTime) {
      clearStorage("admin");
    }
  } else {
    const token = localStorage.getItem("jwt_employee");
    if (!token) {
      clearStorage("employee");
      navigate("/employeeLogin");
    }
    const decodedToken = jwtDecode(token);
    const expirationTime = decodedToken.exp * 1000;
    const currentTime = new Date().getTime();
    if (currentTime > expirationTime) {
      clearStorage("employee");
    }
  }
};

export const clearStorage = (type) => {
  if (type === "employee") {
    localStorage.removeItem("jwt_employee");
    localStorage.removeItem("employeeId");
    localStorage.removeItem("employeeFirstName");
    localStorage.removeItem("employeeLastName");
    localStorage.removeItem("employeeEmail");
  } else {
    localStorage.removeItem("jwt_admin");
    localStorage.removeItem("userId");
    localStorage.removeItem("adminFirstName");
    localStorage.removeItem("adminLastName");
    localStorage.removeItem("adminEmail");
  }
};
