import axios from 'axios';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AssessmentListDataTable from '../../components/mydatatable/AssessmentListDataTable';
import Navbar from '../../components/navbar/Navbar';
import Sidebar from '../../components/sidebar/Sidebar';
import { validateToken } from '../../utils/localStorageHandling';
import "./assessmentList.scss";

function AssessmentList(props) {
  const [assessmentData, setAssessmentData] = useState([]);
  const navigate = useNavigate();
  useEffect(() => {
    validateToken("admin", navigate);
    axios.get(process.env.REACT_APP_ROOT_URL+"/api/v1/assessment/company",{ params: { companyId:  localStorage.getItem("companyId") ? localStorage.getItem("companyId") : 123 }})
    .then((response) => {
      setAssessmentData(response.data.sort((a,b) => new Date(b.endDate) - new Date(a.endDate)));
      const expired = response.data.filter(assessment => assessment.status==="STARTED").filter(assessment => moment(assessment.endDate).isBefore(new Date(), 'day'));
      const updatedAssessments = expired.map(assessment => {
        assessment.status="COMPLETED";
        return assessment;
      });
      const updatedAssessmentsFinal = updatedAssessments.filter(assessment => assessment);
      axios
      .put(process.env.REACT_APP_ROOT_URL+"/api/v1/assessment/bulk",updatedAssessmentsFinal,{headers: { Authorization: localStorage.getItem("authHeader") },})
      .then(function (response) {
        console.log(response);
      })
      .catch(function (error) {
        console.log(error);
      });

    });
  }, []);
    return (
        <div className="assessmentList">
        <Navbar />
        <div className="assessmentListContainer">
        <Sidebar selectedIndex={5}/>
        <div className="contentArea" style={{width:'50%',minWidth:'1100px'}}>
          <div className="assessmentListTitle"> <h1>Assessments</h1></div>
          <AssessmentListDataTable assessmentData={assessmentData}/>
        </div>
      </div>
      </div>
    );
}

export default AssessmentList;