import { Paper } from '@mui/material';
import React,{useState, useEffect} from 'react';
import Countdown from 'react-countdown';
import ScheduleIcon from "@mui/icons-material/Schedule";
import "./testTimer.scss"

function TestTimer({timerMinutes, timerSeconds, onTimerExpired}) {
    const [minutes, setMinutes] = useState(2);
    const [seconds, setSeconds] = useState(0);
    //pass minutes from props and not from state 
    let interval;
    const startTimer = () => {
      const currentDate = new Date();
      const countDownDate = (new Date(currentDate.getTime() + timerMinutes*60000));
      console.log(countDownDate);
      interval = setInterval(() => {
        const now = new Date().getTime();
        const distance = countDownDate - now;
        const minutes = Math.floor((distance % (60 * 60 * 1000)) / (1000 * 60));
        const seconds = Math.floor((distance % (60 * 1000)) / 1000);
  
        if (distance < 0) {
          // Stop Timer
          onTimerExpired();
          clearInterval(interval.current);
        } else {
          // Update Timer
          setMinutes(minutes);
          setSeconds(seconds);
        }
      });
    };
    useEffect(() => {
        setMinutes(timerMinutes);
     startTimer();
    }, []);
    const renderer = ({ minutes, seconds }) => {
      return (
        <span>
          {minutes}:{seconds}
        </span>
      );
    };
    const Completionist = () => <span>Time's up!</span>;

    return (
        <Paper elevation={5} className="testTimer">
              <h1>{minutes} : {seconds}</h1>
        </Paper>
/*         <Paper elevation={10} className="testTimer">
            <Countdown
            date={Date.now() + timerMinutes* 60 * 1000}
            renderer={renderer}
            onComplete={Completionist}
          />
          </Paper> */
    );
}

TestTimer.defaultProps = {
    timerMinutes:1,
    timerSeconds:0
}

export default TestTimer;