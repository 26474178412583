import React from 'react';
import Employee from '../employee/Employee';

function AdminProfile(props) {
    return (
        <div>
            <Employee type="myProfile" source="admin"  email = {localStorage.getItem("adminEmail")} fullname= {localStorage.getItem("adminFirstName") + " " + localStorage.getItem("adminLastName")} />
            </div>
    );
}

export default AdminProfile;