import { Box, Grid, Paper } from "@mui/material";
import React from "react";
import "../../App.css";
import { createTheme, ThemeProvider } from "@mui/material";

const theme = createTheme({
    components: {
      MuiTypography: {
        variants: [
          {
            props: {
              variant: "body2",
            },
            style: {
              fontSize: 11,
            },
          },
          {
            props: {
              variant: "body3",
            },
            style: {
              fontSize: 9,
            },
          },
        ],
      },
    },
  });

function Experiment1() {
    return (
      <ThemeProvider theme={theme}>
        <Box
          sx={{
            width: 300,
            height: 300,
            backgroundColor: "primary.dark",
            "&:hover": {
              backgroundColor: "primary.main",
              opacity: [0.9, 0.8, 0.7],
            },
          }}
        >
          <Paper elevation={5}>Question 1</Paper>
        </Box>
      </ThemeProvider>
    );
}
export default Experiment1;
