import "./navbar.scss";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import {
  AppBar,
  Avatar,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
} from "@mui/material";
import { styled } from "@mui/system";
import VpnLockOutlinedIcon from "@mui/icons-material/VpnLockOutlined";
import { useState } from "react";
import Person2Icon from "@mui/icons-material/Person2";
import LogoutIcon from "@mui/icons-material/Logout";
import { useNavigate, useNavigationType } from "react-router-dom";
import logo from "./logoWhite.png";
import { clearStorage } from "../../utils/localStorageHandling";
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';

const StyledToolbar = styled(Toolbar)({
  display: "flex",
  justifyContent: "space-between",
});

function Navbar(props) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const navigate = useNavigate();
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleLogout = () => {
    // localStorage.clear();
    clearStorage(props.type);
/*     if (props.type === "employee") {
      localStorage.removeItem("jwt_employee");
      localStorage.removeItem("employeeId");
      localStorage.removeItem("employeeFirstName");
      localStorage.removeItem("employeeLastName");
      localStorage.removeItem("employeeEmail");
    } else {
      localStorage.removeItem("jwt_admin");
      localStorage.removeItem("userId");
      localStorage.removeItem("adminFirstName");
      localStorage.removeItem("adminLastName");
      localStorage.removeItem("adminEmail");
    } */
    /*  localStorage.removeItem("authority");
    localStorage.removeItem("firstName");
    localStorage.removeItem("lastName");
    localStorage.removeItem("email");
    localStorage.removeItem("userId");
    localStorage.removeItem("companyId"); */

    if (props.type === "employee") {
      navigate("/employeeLogin");
    } else {
      navigate("/adminLogin");
    }
  };
  const handleProfileClick = () => {
    localStorage.setItem("sidebarIndex", -1);
    if (props.type === "employee") {
    navigate("/employeeProfile");
    }else{
      navigate("/adminProfile");
    }
  }
  const handleHomePage = () => {
    if (props.type === "employee") {
      navigate("/employeeLogin");
    } else {
      navigate("/myTasks");
    }
  };
  const handleTaskClick = () => {
    navigate("/myTasks");
  }
  return (
    /*     <div className="navbar">
      <div className="wrapper">
        <div className="search">
          <input type="text" placeholder="Search" />
          <SearchOutlinedIcon />
        </div>
      </div>
    </div> */
    <AppBar position="static">
      <StyledToolbar>
        {/*         <Typography variant="h6">
          INCOGNITO <VpnLockOutlinedIcon />
        </Typography>
        <Avatar
          src=""
          onClick={(e) => setAnchorEl(e.target)}
          sx={{ cursor: "pointer" }}
        /> */}
         <img className="logoWhite" src={logo} alt="Logo" onClick={handleHomePage} />
        <Avatar
          src=""
          onClick={(e) => setAnchorEl(e.target)}
          sx={{ cursor: "pointer" }}
        />
        <Menu
          id="demo-positioned-menu"
          aria-labelledby="demo-positioned-button"
          open={open}
          onClose={(e) => setAnchorEl(null)}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          {props.type === "employee" && 
          <MenuItem onClick={handleTaskClick}>
            <AssignmentTurnedInIcon /> Tasks
          </MenuItem>
          }
          <MenuItem onClick={handleProfileClick}>
            <Person2Icon /> Profile
          </MenuItem>
          <MenuItem onClick={handleLogout}>
            <LogoutIcon />
            Logout
          </MenuItem>
        </Menu>
      </StyledToolbar>
    </AppBar>
  );
}

export default Navbar;
