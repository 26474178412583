import { Alert, Autocomplete, Button, Grid, Snackbar, TextField } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Navbar from "../../components/navbar/Navbar";
import Sidebar from "../../components/sidebar/Sidebar";
import { validateToken } from "../../utils/localStorageHandling";
import "./assignAssessment.scss";

function AssignAssessment(props) {
  const top100Films = [
    { id: 1, label: "The Shawshank Redemption" },
    { id: 2, label: "A Clockwork Orange" },
    { id: 3, label: "Like Stars on Earth" },
    { id: 4, label: "Taxi Driver" },
    { id: 5, label: "Lawrence of Arabia" },
  ];
  const [assessment, setAssessment] = useState(null);
  const [assessee, setAssessee] = useState(null);
  const [assessors, setAssessors] = useState([]);
  const [groups, setGroups] = useState([]);
  const [employeeData, setEmployeeData] = useState();
  const [assessmentData, setAssessmentData] = useState();
  const [groupData, setGroupData] = useState();
  const [successSnackBar, setSuccessSnackBar] = useState(false);
  const [errorSnackbar, setErrorSnackBar] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    validateToken("admin", navigate);
    const fetchEmployee = axios.get(process.env.REACT_APP_ROOT_URL+"/api/v1/person/company",{ params: { companyId:  localStorage.getItem("companyId") ? localStorage.getItem("companyId") : 123 },headers: { Authorization: localStorage.getItem("authHeader") },});
    const fetchGroup = axios.get(process.env.REACT_APP_ROOT_URL+"/api/v1/group",{ params: { companyId:  localStorage.getItem("companyId") ? localStorage.getItem("companyId") : 123 },headers: { Authorization: localStorage.getItem("authHeader") },});
    const fetchAssessment = axios.get(process.env.REACT_APP_ROOT_URL+"/api/v1/assessment/company",{ params: { companyId:  localStorage.getItem("companyId") ? localStorage.getItem("companyId") : 123 },headers: { Authorization: localStorage.getItem("authHeader") },});
    axios.all([fetchEmployee, fetchGroup, fetchAssessment]).then(axios.spread((employeeFetch, groupFetch, assessmentFetcg) => {
      //console.log(employeeFetch);
       setEmployeeData(
        employeeFetch.data.map((employee) => ({
          ...employee,
          label: employee.firstName + " " + employee.lastName + " ("+employee.email+")",
        }))
      );
      setGroupData(
        groupFetch.data.map((group) => ({
          ...group,
          label: group.name,
        }))
      );
      setAssessmentData(
        assessmentFetcg.data.map((assessment) => ({
          ...assessment,
          label: assessment.assessmentName,
        }))
      ); 
    })).catch(error => console.log(error))
  },[]);

  const clearForm = ()=> {
    setAssessment(null);
    setAssessee(null);
    setAssessors([]);
    setGroups([]);
  }
  const handleSubmit = (event) => {
    event.preventDefault();
    var assessorIdSet = new Set();
    assessors.forEach(item => assessorIdSet.add(item.id));
    console.log(assessorIdSet);
    groups.forEach((group) => group.assessmentGroupMembers.forEach((member) => {
      if(!assessorIdSet.has(member.id)){
        assessors.push(member);
      }
    }));
    const assessmentTasks = assessors.map((assessor) => {
      if(assessor.id !== assessee.id){
        return(
      {
      assessorId: assessor.id,
      assesseeId: assessee.id,
      status: "STARTED",
      assessmentId: assessment.id,
      companyId:localStorage.getItem("companyId") ? localStorage.getItem("companyId") : 123
    }
        )
  }
  });
   const finalTasks = assessmentTasks.filter(task => task);
    axios
      .post(process.env.REACT_APP_ROOT_URL+"/api/v1/assessment/assign", finalTasks,{
        headers: { Authorization: localStorage.getItem("authHeader") },
      })
      .then(function (response) {
        console.log(response);
        setSuccessSnackBar(true);
        clearForm();
      })
      .catch(function (error) {
        console.log(error);
        setErrorSnackBar(true);
      });
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSuccessSnackBar(false);
    setErrorSnackBar(false);
  };

  return (
    <div className="assignAssessment">
      <Navbar />
      <div className="assignAssessmentContainer">
        <Sidebar selectedIndex={3} />
        <div className="contentArea" style = {{width: '50%'}}>
          <div className="topAssignAssessment">
            <h1>Assign Assessment</h1>
          </div>
          <div className="bottomAssignAssessment">
          <form onSubmit={handleSubmit}>
            <Grid
              container
              direction="column"
              spacing={3}
              justifyContent="center"
              alignItems="flex-start"
            >
              <Grid item xs className="gridItem">
                <label>Assessment Name</label>
                <Autocomplete
                  disablePortal
                  id="assessment-name"
                  value={assessment}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  onChange={(event, newValue) => {
                    //console.log(newValue);
                    setAssessment(newValue);
                  }}
                  options={assessmentData ? assessmentData : []}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Search Assessments..."
                      required
                    />
                  )}
                />
              </Grid>
              <Grid item xs className="gridItem">
                <label>Assessee Name</label>
                <Autocomplete
                  disablePortal
                  id="assesse-name"
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  value={assessee}
                  options={employeeData ? employeeData : []}
                  onChange={(event, newValue) => {
                    console.log(newValue);
                    setAssessee(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Search Assessee Name..."
                    />
                  )}
                />
              </Grid>
              <Grid item xs className="gridItem">
                <label>Assessors</label>
                <Autocomplete
                  multiple
                  id="assessor-name"
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  options={employeeData ? employeeData : []}
                  value={assessors}
                  onChange={(event, newValue) => {
                    console.log(newValue);
                    setAssessors(newValue);
                  }}
                  getOptionLabel={(option) => option.label}
                  //defaultValue={[top100Films[13]]}
                  renderInput={(params) => (
                    <TextField {...params} placeholder="Search Assessors..." />
                  )}
                />
              </Grid>
              <Grid item xs className="gridItem">
                <label>Teams (optional)</label>
                <Autocomplete
                  multiple
                  id="group-name"
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  value={groups}
                  onChange={(event, newValue) => {
                    console.log(newValue);
                    setGroups(newValue);
                  }}
                  options={groupData ? groupData : []}
                  getOptionLabel={(option) => option.label}
                  //defaultValue={[top100Films[13]]}
                  renderInput={(params) => (
                    <TextField {...params} placeholder="Search Teams..." />
                  )}
                />
              </Grid>
              <Grid item xs className="gridItem">
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  className="assignButton"
                >
                  Assign
                </Button>
              </Grid>
              {/* <button type="button" className="createButton">Assign</button> */}
            </Grid>
            </form>
            <Snackbar
        open={successSnackBar}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical:'top', horizontal:'right' }}
      >
        <Alert onClose={handleClose} severity="success" sx={{ width: "100%" }}>
          Assessment Assigned Successfully!
        </Alert>
      </Snackbar>
      <Snackbar
        open={errorSnackbar}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical:'top', horizontal:'right' }}
      >
        <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
        Error in Assigning Assessment.Please contact Support !
        </Alert>
      </Snackbar>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AssignAssessment;
