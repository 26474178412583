import "./experiment2.scss";
import { Button, TextField } from "@mui/material";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import React, { useState } from "react";
import Grid from "@mui/material/Grid";

function Experiment2(props) {
  const [startDate, setStartDate] = useState(dayjs());
  const [endDate, setEndDate] = useState(dayjs());

  const handleChangeStartDate = (newValue) => {
    setStartDate(newValue);
  };
  const handleChangeEndDate = (newValue) => {
    setEndDate(newValue);
  };
  return (
    <div className="experiment2">
        <Grid container direction="column" spacing={3} justifyContent="center" alignItems="flex-start">
          <Grid item xs className="gridItem">
            <TextField
              fullWidth
              id="standard-basic"
              label="Assessment Name"
              variant="outlined"
            />
          </Grid>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Grid item xs className="gridItem">
              <DesktopDatePicker
                label="Start Date"
                inputFormat="MM/DD/YYYY"
                value={startDate}
                onChange={handleChangeStartDate}
                renderInput={(params) => <TextField fullWidth {...params} />}
              />
            </Grid>
            <Grid item xs className="gridItem">
              <DesktopDatePicker
                label="End Date"
                inputFormat="MM/DD/YYYY"
                value={endDate}
                onChange={handleChangeEndDate}
                renderInput={(params) => <TextField fullWidth {...params} />}
              />
            </Grid>
          </LocalizationProvider>
          <Grid item xs className="gridItem">
            <TextField
              fullWidth
              id="standard-basic"
              label="Number of Questions"
              variant="outlined"
              type="number"
            />
          </Grid>
          <Grid item xs className="gridItem">
            <TextField
              fullWidth
              id="standard-basic"
              label="Assessment Duration(in min)"
              variant="outlined"
              type="number"
            />
          </Grid>
          <Grid item xs className="gridItem">
            <Button variant="contained" className="createButton">
              Create
            </Button>
          </Grid>
        </Grid>
    </div>
  );
}

export default Experiment2;
