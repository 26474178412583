import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";
import React, { useEffect, useState } from "react";
import "./taskCompletionStatus.scss";

function TaskCompletionStatus(props) {
  const [taskCompletionData, setTaskCompletionData] = useState();
  const taskCompletionColumns = [
    {
      field: "firstName",
      headerName: "First Name",
      width: 120,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "lastName",
      headerName: "Last Name",
      width: 120,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "taskStatus",
      headerName: "Task Status",
      width: 120,
      align: "center",
      headerAlign: "center",
    },
  ];
  useEffect(() => {
    axios
      .get(
        process.env.REACT_APP_ROOT_URL +
          "/api/v1/assessment/taskStatusDetailsForEmployee",
        {
          params: {
            assessmentId: props.assessmentId ? props.assessmentId : 123,
            assesseeId: props.assesseeId ? props.assesseeId : 1,
          },
          headers: { Authorization: localStorage.getItem("authHeader") },
        }
      )
      .then((response) => {
        setTaskCompletionData(response.data);
      });
  }, []);
  return (
    <div className="taskCompletionGrid">
      <DataGrid
        rows={taskCompletionData ? taskCompletionData : []}
        columns={taskCompletionColumns}
        pageSize={10}
        rowsPerPageOptions={[10]}
        // checkboxSelection
      />
    </div>
  );
}

export default TaskCompletionStatus;
