import { Button } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import companyLogo from './logo.png';
import { useParams } from 'react-router-dom';
import { useEffect } from 'react';
import axios from 'axios';
import { useState } from 'react';

const EmailVerification = (props) => {
    const navigate = useNavigate();
    const {token} = useParams();
    const[message,setMessage] = useState();
    const[subtext,setsubtext] = useState();
    const[displayButton, setDisplayButton] = useState(false);

    const onLogin = () => {
        navigate("/employeeLogin")
    }

    useEffect(() => {
        axios
        .get(process.env.REACT_APP_ROOT_URL+"/api/v1/registration/confirm", {
          params: {
            token: token,
          }
        })
        .then((response) => {
          setMessage("Email Verified Successfully");
          setsubtext("Thank you for verifying your email address.");
          setDisplayButton(true);
        })
        .catch((error) => {
            setMessage("Email could not be Verified");
            setsubtext("Please contact support.");
            setDisplayButton(false);
        });

    },[])
  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '100px' }}>
      <img src={companyLogo} alt="Company Logo" style={{ width: '200px', marginBottom: '50px' }} />
      <h1 style={{ fontSize: '2em', marginBottom: '25px' }}>{message}</h1>
      <p style={{ fontSize: '1.5em', marginBottom: '50px' }}>{subtext}</p>
      {displayButton && <Button variant="contained"
      onClick={onLogin}>
        Login
      </Button>
}
    </div>
  );
};

export default EmailVerification;
