import "./homenavbar.scss";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import {
  AppBar,
  Avatar,
  Backdrop,
  Box,
  Button,
  Fade,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Modal,
  Toolbar,
  Typography,
} from "@mui/material";
import { styled } from "@mui/system";
import VpnLockOutlinedIcon from "@mui/icons-material/VpnLockOutlined";
import { useState } from "react";
import Person2Icon from "@mui/icons-material/Person2";
import LogoutIcon from "@mui/icons-material/Logout";
import { Link, useNavigate, useNavigationType } from "react-router-dom";
import logo from "./logo.png";
import ContactUs from "../../pages/contactUs/ContactUs";
import MenuIcon from "@mui/icons-material/Menu";

const StyledToolbar = styled(Toolbar)({
  display: "flex",
  justifyContent: "space-between",
});
const pages = ["Home", "Process", "Benefits"];

function HomeNavbar(props) {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    //width: 400,
    bgcolor: "background.paper",
    //border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };
  const [anchorElNav, setAnchorElNav] = useState(null);
  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState(false);
  const handleOpen = () => setOpenModal(true);
  const handleClose = () => setOpenModal(false);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };
  const handleNavClick = (event) => {
    event.preventDefault();
    const sectionId = event.currentTarget.getAttribute("href");
    const replacedSectionId = sectionId.replace("/", "");
    const section = document.querySelector(replacedSectionId);
    section.scrollIntoView({ behavior: "smooth" });
  };
  const handleLogin = () => {
    navigate("/employeeLogin");
  };
  const handleHomePage = () => {
    navigate("/");
  };
  return (
    <AppBar position="sticky" className="homenavbar">
      <StyledToolbar>
        <Grid sx={{ display: { xs: "none", md: "flex" } }}>
          <img
            className="logoImg"
            src={logo}
            alt="Logo"
            onClick={handleHomePage}
          />
        </Grid>
        <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
          <IconButton
            size="large"
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleOpenNavMenu}
            color="inherit"
            className="iconButton"
          >
            <MenuIcon />
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={anchorElNav}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            open={Boolean(anchorElNav)}
            onClose={handleCloseNavMenu}
           
          >
            <MenuItem key={7} >
              <Link to="#homeComp" onClick={handleNavClick} className="myMenuItem">
                <Typography variant="h6" component="h6">
                  Home
                </Typography>
              </Link>
            </MenuItem>
            <MenuItem key={8}>
              <Link to="#processComp" onClick={handleNavClick}>
                <Typography variant="h6" component="h6">
                  Process
                </Typography>
              </Link>
            </MenuItem>
            <MenuItem key={9}>
              <Link to="#benefitsComp" onClick={handleNavClick}>
                <Typography variant="h6" component="h6">
                  Benefits
                </Typography>
              </Link>
            </MenuItem>
          </Menu>
          <img
            className="logoImgShort"
            src={logo}
            alt="Logo"
            onClick={handleHomePage}
          />
        </Box>


        <Grid
          container
          direction="row"
          spacing={12}
          justifyContent="flex-end"
          alignItems="center"
          className="navGrid"
          sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}
        >
          <Grid item xs className="gridItem">
            <Link to="#homeComp" onClick={handleNavClick}>
              <Typography variant="h6" component="h6">
                Home
              </Typography>
            </Link>
          </Grid>
          <Grid item xs className="gridItem">
            <Link to="#processComp" onClick={handleNavClick}>
              <Typography variant="h6" component="h6">
                {" "}
                Process
              </Typography>
            </Link>
          </Grid>
          <Grid item xs className="gridItem">
            <Link to="#benefitsComp" onClick={handleNavClick}>
              <Typography variant="h6" component="h6">
                Benefits
              </Typography>
            </Link>
          </Grid>
          <Grid item xs className="gridItem">
            <Button
              variant="contained"
              onClick={handleOpen}
              sx={{
                borderRadius: "25px",
                padding: " 10px 17px",
                //width: "100%",
              }}
            >
              Book a Demo
            </Button>
          </Grid>
          <Grid item xs className="gridItem">
            <Button
              variant="outlined"
              sx={{ borderRadius: "25px", padding: " 10px 30px" }}
              onClick={handleLogin}
            >
              Log in
            </Button>
            <Modal
              aria-labelledby="transition-modal-title"
              aria-describedby="transition-modal-description"
              open={openModal}
              onClose={handleClose}
              closeAfterTransition
              BackdropComponent={Backdrop}
              BackdropProps={{
                timeout: 500,
              }}
            >
              <Fade in={openModal}>
                <Box sx={style}>
                  <ContactUs title="Book a Demo" />
                </Box>
              </Fade>
            </Modal>
          </Grid>
        </Grid>
      </StyledToolbar>
    </AppBar>
  );
}

export default HomeNavbar;
