import React from 'react';
import Login from '../login/Login';

function AdminLogin(props) {
    return (
        <div>
            <Login type="admin"/>
        </div>
    );
}

export default AdminLogin;