import { Button, Paper, Tab, Tabs, Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";
import jwtDecode from "jwt-decode";
import moment from "moment/moment";
import React, { useEffect, useState } from "react";
import Navbar from "../../components/navbar/Navbar";
import "./taskPageGrid.scss";
import data from "../../resources/data";
import { useNavigate } from "react-router-dom";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import ScheduleIcon from "@mui/icons-material/Schedule";
import { Box } from "@mui/system";
import { validateToken } from "../../utils/localStorageHandling";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function TaskPageGrid(props) {
  const [assessmentTasks, setAssessmentTasks] = useState([]);
  const [value, setValue] = useState(0);
  let navigate = useNavigate();
  const employeeTaskColumns = [
    {
      field: "firstName",
      headerName: "First Name",
      //width: 200,
      minWidth: 100,
      flex:1,
      align: "center",
      headerAlign: "center",
      valueGetter: (params) => params.row.assessee.firstName,
      renderHeader: (params) => <b>First Name</b>,
    },
    {
      field: "lastName",
      headerName: "Last Name",
      //width: 200,
      minWidth: 100,
      flex:1,
      align: "center",
      headerAlign: "center",
      valueGetter: (params) => params.row.assessee.lastName,
      renderHeader: (params) => <b>Last Name</b>,
    },
    {
      field: "assessmentNamr",
      headerName: "Assessment Name",
      //width: 220,
      minWidth: 150,
      flex:1.5,
      align: "center",
      headerAlign: "center",
      valueGetter: (params) => params.row.assessment.assessmentName,
      renderHeader: (params) => <b>Assessment Name</b>,
    },
    {
      field: "duration",
      headerName: "Duration (in min)",
      //width: 180,
      minWidth: 110,
      flex:1,
      align: "center",
      headerAlign: "center",
      valueGetter: (params) => params.row.assessment.duration,
      renderHeader: (params) => (
        <div className="endDateFormat">
          <ScheduleIcon /> &nbsp; <b>Duration (in min)</b>{" "}
        </div>
      ),
    },
    {
      field: "endDate",
      headerName: "End Date",
      type: "date",
      //width: 220,
      minWidth: 120,
      flex:1.2,
      align: "center",
      headerAlign: "center",
      valueGetter: (params) =>
        moment(params.row.assessment.endDate, "YYYY-MM-DD").format(
          "DD-MM-YYYY"
        ),
      renderHeader: (params) => (
        <div className="endDateFormat">
          <CalendarMonthIcon /> &nbsp; <b>End Date</b>
        </div>
      ),
    },
    {
      field: "action",
      headerName: "Action",
      //width: 220,
      minWidth: 110,
      flex:1.1,
      align: "center",
      headerAlign: "center",
      valueGetter: (params) =>
        moment(params.row.assessment.endDate, "YYYY-MM-DD").format(
          "DD-MM-YYYY"
        ),
      renderCell: (params) => (
        <Button
          variant="contained"
          onClick={() => {
            handleSubmit(params);
          }}
        >
          Start
        </Button>
      ),
      renderHeader: (params) => <b>Action</b>,
    },
    {
        field: "modifiedAt",
        headerName: "Completion Date",
        type: "date",
        minWidth:110,
        flex:1.1,
        //width: 220,
        align: "center",
        headerAlign: "center",
        valueGetter: (params) =>
          moment(params.row.modifiedAt, "YYYY-MM-DD").format(
            "DD-MM-YYYY"
          ),
        renderHeader: (params) => (
          <div className="endDateFormat">
            <CalendarMonthIcon /> &nbsp; <b>Completion Date</b>
          </div>
        ),
      },
  ];
  const handleSubmit = (params) => {
    data.questions = shuffleArray(params.row.questions);
    data.duration = params.row.assessment.duration;
    data.assessmentId = params.row.assessment.id;
    data.assessmentTaskId = params.row.id;
    console.log("start clicked");
    let path = `/test`;
    navigate(path);

    /*       return(
        <Route path="/test" element={<TestPage questions={props.assessment.questions} />} />
      ) */
  };
  const shuffleArray = (array) => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  };
  useEffect(() => {
    validateToken("employee", navigate);
    axios
      .get(
        process.env.REACT_APP_ROOT_URL + "/api/v1/assessment/tasks/assessor",
        {
          params: {
            companyId: localStorage.getItem("companyId")
              ? localStorage.getItem("companyId")
              : 123,
            assessorId: localStorage.getItem("employeeId")
              ? localStorage.getItem("employeeId")
              : 1,
          },
          headers: { Authorization: localStorage.getItem("authHeader") },
        }
      )
      .then((response) => {
        console.log(response.data);
        setAssessmentTasks(response.data.sort((a,b) => new Date(a.assessment.endDate) - new Date(b.assessment.endDate)));
      });
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div>
      <Navbar type="employee" />
      <Paper elevation={10} className="taskPagePaper">
        <div className="taskPageTitle">
          <h1>My Tasks</h1>
        </div>
        <Box sx={{ width: "100%" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab label="Active" {...a11yProps(0)} />
              <Tab label="Completed" {...a11yProps(1)} />
              <Tab label="Expired" {...a11yProps(2)} />
            </Tabs>
          </Box>
          <TabPanel value={value} index={0} className="tabPanelStyle">
          <div className="taskPageGrid">
          <DataGrid
            rows={
              assessmentTasks
                ? assessmentTasks.filter((item) => item.status !== "COMPLETED" &&  moment(item.assessment.endDate).isSameOrAfter(new Date(), 'day'))
                : []
            }
            columns={employeeTaskColumns.filter((item) => item.field!=="modifiedAt")}
            pageSize={10}
            rowsPerPageOptions={[10]}
            // checkboxSelection
          />
        </div>
          </TabPanel>
          <TabPanel value={value} index={1}>
          <div className="taskPageGrid">
          <DataGrid
            rows={
              assessmentTasks
                ? assessmentTasks.filter((item) => item.status === "COMPLETED").sort((a,b) => new Date(b.modifiedAt) - new Date(a.modifiedAt))
                : []
            }
            columns={employeeTaskColumns.filter((item) => (item.field!=="action" && item.field!=="endDate"))}
            pageSize={10}
            rowsPerPageOptions={[10]}
            // checkboxSelection
          />
        </div>
          </TabPanel>
          <TabPanel value={value} index={2}>
          <div className="taskPageGrid">
          <DataGrid
            rows={
              assessmentTasks
                ? assessmentTasks.filter((item) => (item.status !== "COMPLETED" &&  moment(item.assessment.endDate).isBefore(new Date(), 'day'))).sort((a,b) => new Date(b.modifiedAt) - new Date(a.modifiedAt))
                : []
            }
            columns={employeeTaskColumns.filter((item) => (item.field!=="action" && item.field!=="modifiedAt"))}
            pageSize={10}
            rowsPerPageOptions={[10]}
            // checkboxSelection
          />
        </div>
          </TabPanel>
        </Box>

      </Paper>
    </div>
  );
}

export default TaskPageGrid;
