import axios from "axios";
import React, { useEffect } from "react";
import AssessmentGroup from "../../components/group/AssessmentGroup";
import Navbar from "../../components/navbar/Navbar";
import Sidebar from "../../components/sidebar/Sidebar";
import "./createGroup.scss";

function CreateGroup(props) {


  return (
    <div className="createGroup">
      <Navbar />
      <div className="newContainer">
        <Sidebar selectedIndex={6} />
        <div className="contentArea" style={{width: '40%', minWidth:'500px'}}>
        <div className="topCreateGroup">
          <h1>Create Team</h1>
        </div>
        <AssessmentGroup type="create" />
      </div>
      </div>
    </div>
  );
}

export default CreateGroup;
