import React , {useState, useEffect} from "react";
import QuestionCard from "../../components/questioncard/QuestionCard";
import TestTimer from "../../components/timer/TestTimer";
import "./testPage.scss";
import data from "../../resources/data";
import { useNavigate } from "react-router-dom";
import jwtDecode from 'jwt-decode';
import { validateToken } from "../../utils/localStorageHandling";

function TestPage(props) {
const[testComplete, setTestComplete] = useState(false);
const[timerExpired, setTimerExpired] = useState(false);

  const navigate = useNavigate();
 // console.log(data);
  if(JSON.stringify(data) === '{}'){
    localStorage.removeItem("jwt_employee");
    //navigate("/myTasks");
  }
/*     const [timerMinutes, setTimerMinutes] = useState(2);
    const [timerSeconds, setTimerSeconds] = useState(0);
    //pass minutes from props and not from state 
    let interval;
    const startTimer = () => {
      const currentDate = new Date();
      const countDownDate = (new Date(currentDate.getTime() + 2*60000));
      console.log(countDownDate);
      interval = setInterval(() => {
        const now = new Date().getTime();
        const distance = countDownDate - now;
        const minutes = Math.floor((distance % (60 * 60 * 1000)) / (1000 * 60));
        const seconds = Math.floor((distance % (60 * 1000)) / 1000);
  
        if (distance < 0) {
          // Stop Timer
  
          clearInterval(interval.current);
        } else {
          // Update Timer
          setTimerMinutes(minutes);
          setTimerSeconds(seconds);
        }
      });
    };
    useEffect(() => {
     // startTimer();
    }, []); */
    useEffect(() => {
      validateToken("employee", navigate);
      if(JSON.stringify(data) === '{}'){
        navigate("/myTasks");
      }
     }, []);
    const onTestComplete = () => {
      setTestComplete(true);
    }
    const onTimerExpired = () => {
      setTimerExpired(true);
    }

  return (
    <div className="testPage">
      <div className="myTimer">
        {!testComplete && 
      <TestTimer timerMinutes={data.duration} timerSeconds={0} onTimerExpired={onTimerExpired}/>
        }
      </div>
      <div className="myQuestionCard"><QuestionCard questions = {data.questions} onTestComplete={onTestComplete} timerExpired={timerExpired} assessmentId = {data.assessmentId} assessmentTaskId = {data.assessmentTaskId}/></div>

    </div>
  );
}

export default TestPage;
