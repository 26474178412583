import React from 'react';
import Employee from '../employee/Employee';

function EmployeeProfile(props) {
    return (
        <div>
            <Employee type="myProfile" source="employee" email = {localStorage.getItem("employeeEmail")} fullname= {localStorage.getItem("employeeFirstName") + " " + localStorage.getItem("employeeLastName")}/>
            </div>
    );
}

export default EmployeeProfile;