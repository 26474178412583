import React from "react";
import Assessment from "../../components/asseessmentcard/Assessment";
import Navbar from "../../components/navbar/Navbar";
import Sidebar from "../../components/sidebar/Sidebar";
import "./createAssessment.scss";

function CreateAssessment(props) {

  return (
    <div className="createAssessment">
      <Navbar />  
      <div className="newContainer">
      <Sidebar selectedIndex = {2}/>
      <div className="contentArea" style={{width:'50%'}}>
        <div className="topCreateAssessment">
          <h1>Create Assessment</h1>
        </div>
        <Assessment type="fresh"/>
      </div>
    </div>
    </div>
  );
}

export default CreateAssessment;
