import "./questioncard.scss";
import { answerOptions } from "../../resources/questions";
import { useEffect, useState } from "react";
import { CircularProgress, Grid } from "@mui/material";
import { NavLink, useNavigate } from "react-router-dom";
import axios from "axios";
import { Box } from "@mui/system";
import { set } from "date-fns/esm";

function QuestionCard(props) {
  const navigate = useNavigate();
  var myMap = new Map();
  myMap.set("Strongly Agree", 5);
  myMap.set("Agree", 4);
  myMap.set("Neither Agree nor Disagree", 3);
  myMap.set("Disagree", 2);
  myMap.set("Strongly Disagree", 1);
  // myMap.set(‘language’,’JavaScript’);
  //console.log(props);
  // console.log(props.questions);
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [testComplete, setTestComplete] = useState(false);
  const [openness, setOpenness] = useState(0);
  const [opennessId, setOpennessId] = useState();
  const [conscientiousness, setConscientiousness] = useState(0);
  const [conscientiousnessId, setConscientiousnessId] = useState();
  const [extraversion, setExtraversion] = useState(0);
  const [extraversionId, setExtraversionId] = useState();
  const [agreeableness, setAgreeableness] = useState(0);
  const [agreeablenessId, setAgreeablenessId] = useState();
  const [neuroticism, setNeuroticism] = useState(0);
  const [neuroticismId, setNeuroticismId] = useState();
  const [assessmentTaskScores, setAssessmentTaskScores] = useState();
  const [scoreSubmitted, setScoreSubmitted] = useState(false);
  const [parameterCount, setParameterCount] = useState(0);
  function timeout(delay) {
    return new Promise((res) => setTimeout(res, delay));
  }

  const handleAnswerButtonClick = async (event) => {
    const nextQuestions = currentQuestion + 1;
    const scoringType = props.questions[currentQuestion].scoringType;
    const paramterName =
      props.questions[currentQuestion].parameter.parameterName;
    const paramterId = props.questions[currentQuestion].parameter.id;
    const answerText = event.target.innerText;
    const score =
      scoringType === 1 ? myMap.get(answerText) : 6 - myMap.get(answerText);
    console.log(paramterName + " score: " +score);
    if (paramterName.toLowerCase() === "openness") {
      setOpenness((prevScore) => prevScore + score);
      setOpennessId(paramterId);
    }
    if (paramterName.toLowerCase() === "conscientiousness") {
      setConscientiousness(conscientiousness + score);
      setConscientiousnessId(paramterId);
    }
    if (paramterName.toLowerCase() === "extraversion") {
      setExtraversion(extraversion + score);
      setExtraversionId(paramterId);
    }
    if (paramterName.toLowerCase() === "agreeableness") {
      setAgreeableness(agreeableness + score);
      setAgreeablenessId(paramterId);
    }
    if (paramterName.toLowerCase() === "neuroticism") {
      setNeuroticism(neuroticism + score);
      setNeuroticismId(paramterId);
    }
    setParameterCount(parameterCount + 1);

    /*     console.log(scoringType);
    console.log(event.target.innerText); */

    if (nextQuestions < props.questions.length) {
      setCurrentQuestion(nextQuestions);
    } else {
      setTestComplete(true);
      props.onTestComplete();

      /*      setScaledValues({
        [openness]: openness * scaling,
        conscientiousness: conscientiousness * scaling,
        extraversion: extraversion * scaling,
        agreeableness: agreeableness * scaling,
        neuroticism: neuroticism * scaling,
      }); */

      //await timeout(1000);
      // props.onTestComplete();
    }
  };
  useEffect(() => {
    if(props===undefined){
      navigate("/myTasks");
    }
    if (
      testComplete
    ) {
      // percentage = (actual score)/(max score for that parameter)
      // max score for that parameter = max score/5
      // scaled = 10*5*2/maxscore
      const maxScore = props.questions.length * 5;
      console.log("openness: "+openness);
      console.log("conscientiousness: "+conscientiousness);
      console.log("extraversion: "+extraversion);
      console.log("agreeableness: "+agreeableness);
      console.log("neuroticism: "+neuroticism);
      const scaling = (10 * 5) / maxScore;
      setAssessmentTaskScores([
        {
          assessmentTaskId: props.assessmentTaskId,
          parameterId: opennessId,
          score: openness * scaling,
        },
        {
          assessmentTaskId: props.assessmentTaskId,
          parameterId: conscientiousnessId,
          score: conscientiousness * scaling,
        },
        {
          assessmentTaskId: props.assessmentTaskId,
          parameterId: extraversionId,
          score: extraversion * scaling,
        },
        {
          assessmentTaskId: props.assessmentTaskId,
          parameterId: agreeablenessId,
          score: agreeableness * scaling,
        },
        {
          assessmentTaskId: props.assessmentTaskId,
          parameterId: neuroticismId,
          score: neuroticism * scaling,
        },
      ]);
    }
  }, [
    testComplete
  ]);

  useEffect(() => {
    if(props===undefined){
      navigate("/myTasks");
    }
    if (assessmentTaskScores) {
      axios
        .post(process.env.REACT_APP_ROOT_URL+"/api/v1/assessment/taskScores", assessmentTaskScores)
        .then(function (response) {
          //console.log(response);
          setScoreSubmitted(true);
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  }, [assessmentTaskScores]);
  return (
    <div className="questioncard">
        <style>
        {
          `button:hover {
            background-color: #555e7d ;
          } `
        }
      </style>
      {/* HINT: replace "false" with logic to display the 
  score when the user has answered all the questions */}

      {/* {timerExpired ? (alert("restart bitch")) : (<div />)} */}
      {testComplete || props.timerExpired ? (
        <div className="score-section">
          {!scoreSubmitted ? props.timerExpired ?  (
            "Timer Expired !"
          ) : (
            <Box sx={{ display: "flex" }}>
              <CircularProgress />
            </Box>
          ) : testComplete ? (
            "Thanks for completing the assessment !"
          ) : (<div />)}
          {scoreSubmitted || props.timerExpired ? (
            <NavLink  to="/myTasks" className="navStyle">Go back to my tasks</NavLink>
          ) : (
            <div />
          )}
          {/*  {testComplete ? 'Thanks for completing the assessment !'  : 'Timer Expired !' } */}
        </div>
      ) : (
        <>
          {/* <div class="timer"></div> */}
          <div className="question-section">
            <div className="question-count">
              <span>Question {currentQuestion + 1}</span>/
              {props.questions.length}
            </div>
            <div className="question-text">
              {props.questions[currentQuestion]
                ? props.questions[currentQuestion].description
                : "Hello"}
            </div>
          </div>
          <Grid
            container
            direction="column"
            spacing={1}
            className="answer-section"
          >
            {answerOptions.answerOptions.map((answerOption) => (
              <Grid item sx>
                <button
                  className="answerBUtton"
                  onClick={handleAnswerButtonClick}
                 >
                  {answerOption.answerText}
                </button>
              </Grid>
            ))}
          </Grid>
        </>
      )}
    </div>
  );
}

export default QuestionCard;
