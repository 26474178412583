import { DataGrid,GridToolbar } from "@mui/x-data-grid";
import { Link } from "react-router-dom";
import { assessmentColumns } from "../../resources/assessmentsData";
import "./employeeListDataTable.scss";

function AssessmentListDataTable(props) {

        const actionColumn = [
            {
              field: "action",
              headerName: "Action",
              minWidth: 70,
              flex:0.7,
              align: "center",
              headerAlign: "center",
              renderCell: (params) => {
                return (
                  <div className="cellAction">
                    <Link to={`/assessments/${params.row.id}`} style={{ textDecoration: "none" }}>
                      <div className="viewButton">View</div>
                    </Link>
                  </div>
                );
              },
            },
          ];



          return (
            <div className="myDataTable">
              <DataGrid
                rows={props.assessmentData ? props.assessmentData : []}
                columns={assessmentColumns.concat(actionColumn)}
                pageSize={10}
                rowsPerPageOptions={[10]}
                components={{ Toolbar: GridToolbar }}
                isRowSelectable = {true}
                // checkboxSelection
              />
            </div>
          );
}

export default AssessmentListDataTable;