
import "./sidebarnew.scss";
import DashboardIcon from "@mui/icons-material/Dashboard";
import PeopleIcon from "@mui/icons-material/People";
import LogoutIcon from "@mui/icons-material/Logout";
import AssessmentIcon from "@mui/icons-material/Assessment";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ManageSearchIcon from "@mui/icons-material/ManageSearch";
import Person2Icon from "@mui/icons-material/Person2";
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';
import { Link } from "react-router-dom";

function SidebarNew(props) {
  return (
    <div className="sidebar">
      <div className="top">
        <Link to="/" style={{ textDecoration: "none" }}>
          <span className="logo">Incognito</span>
        </Link>
      </div>
      <hr />
      <div className="center">
        <ul>
          <p className="title">DASHBOARD</p>
          <Link to="/" style={{ textDecoration: "none" }}>
            <li>
              <DashboardIcon className="icon" />
              <span>Overview</span>
            </li>
          </Link>
          <p className="title">EMPLOYEE</p>
          <Link to="/employees" style={{ textDecoration: "none" }}>
            <li>
              <PeopleIcon className="icon" />
              <span>Employees</span>
            </li>
          </Link>
          <p className="title">ASSESSMENTS</p>
          <Link to="/createAssessment" style={{ textDecoration: "none" }}>
            <li>
              <AssessmentIcon className="icon" />
              <span>Create Assessment</span>
            </li>
          </Link>
          <Link to="/assignAssessment" style={{ textDecoration: "none" }}>
            <li>
              <ArrowForwardIcon className="icon" />
              <span>Assign Assessment</span>
            </li>
          </Link>
          <Link to="/assessments" style={{ textDecoration: "none" }}>
          <li>
            <ManageSearchIcon />
            <span>Search Assessment</span>
          </li>
          </Link>
          <p className="title">GROUPS</p>
          <Link to="/createGroup" style={{ textDecoration: "none" }}>
          <li>
            <GroupsOutlinedIcon />
            <span>Create Group</span>
          </li>
          </Link>
          <Link to="/editGroup" style={{ textDecoration: "none" }}>
          <li>
            <GroupsOutlinedIcon />
            <span>Edit Group</span>
          </li>
          </Link>
          <p className="title">USER</p>
          <li>
            <Person2Icon className="icon" />
            <span>Profile</span>
          </li>
          <li>
            <LogoutIcon className="icon" />
            <span>Logout</span>
          </li>
        </ul>
      </div>
      <div className="bottom">
        <div className="colorOption"></div>
        <div className="colorOption"></div>
      </div>
    </div>
  );
}

export default SidebarNew;
