import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import EmployeeListDataTable from "../../components/mydatatable/EmployeeListDataTable";
import Navbar from "../../components/navbar/Navbar";
import Sidebar from "../../components/sidebar/Sidebar";
import { validateToken } from "../../utils/localStorageHandling";
import "./employeeList.scss";

function EmployeeList(props) {
  const [employeesAssessmentData, setEmployeesAssessmentData] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {

    validateToken("admin", navigate);
    axios.get(process.env.REACT_APP_ROOT_URL+"/api/v1/person/allEmployeeScores",
    { params: { companyId:  localStorage.getItem("companyId") ? localStorage.getItem("companyId") : 123 },
    headers: { Authorization: localStorage.getItem("authHeader") },},
    )
    .then((response) => {
      setEmployeesAssessmentData(response.data)
    });
  }, []);
  return (
    <div className="employeeList">
      <Navbar />
      <div className="employeeListContainer">
      <Sidebar selectedIndex = {1}/>
      <div className="contentArea" style={{width:'60%',minWidth:'1300px'}}>
        <div className="employeListTitle"> <h1>Employees List</h1></div>
        <EmployeeListDataTable employeesAssessmentData={employeesAssessmentData}/>
      </div>
    </div>
    </div>
  );
}

export default EmployeeList;
