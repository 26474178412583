import moment from "moment";

export const employeeAssessmentColumns = [
  {
    field: "assessmentName",
    headerName: "Assessment",
    minWidth: 140,
    flex: 1.4,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "startDate",
    headerName: "Start Date",
    type: "date",
    minWidth: 110,
    flex: 1.1,
    align: "center",
    headerAlign: "center",
    valueGetter: (params) =>
      moment(params.row.startDate, "YYYY-MM-DD").format("DD-MM-YYYY"),
  },
  {
    field: "endDate",
    headerName: "End Date",
    type: "date",
    minWidth: 110,
    flex: 1.1,
    align: "center",
    headerAlign: "center",
    valueGetter: (params) =>
      moment(params.row.endDate, "YYYY-MM-DD").format("DD-MM-YYYY"),
  },
  {
    field: "openness",
    headerName: "Openness",
    type: "number",
    minWidth: 80,
    flex: 0.8,
    align: "center",
    headerAlign: "center",
    valueGetter: (params) => params.row.openness.toFixed(1),
  },
  {
    field: "conscientiousness",
    headerName: "Conscientiousness",
    type: "number",
    minWidth: 140,
    flex: 1.4,
    align: "center",
    headerAlign: "center",
    valueGetter: (params) => params.row.conscientiousness.toFixed(1),
  },
  {
    field: "extraversion",
    headerName: "Extraversion",
    type: "number",
    minWidth: 100,
    flex: 1,
    align: "center",
    headerAlign: "center",
    valueGetter: (params) => params.row.extraversion.toFixed(1),
  },
  {
    field: "agreeableness",
    headerName: "Agreeableness",
    type: "number",
    minWidth: 110,
    flex: 1.1,
    align: "center",
    headerAlign: "center",
    valueGetter: (params) => params.row.agreeableness.toFixed(1),
  },
  {
    field: "neuroticism",
    headerName: "Neuroticism",
    type: "number",
    minWidth: 100,
    flex: 1,
    align: "center",
    headerAlign: "center",
    valueGetter: (params) => params.row.neuroticism.toFixed(1),
  },
];

//Temp data
export const employeeAssessmentRows = [
  {
    id: "1",
    assessmentName: "Q1 Assessment",
    startDate: "10/10/2020",
    endDate: "12/10/2020",
    openness: 1,
    conscientiousness: 2,
    extraversion: 3,
    agreeableness: 4,
    neuroticism: 5,
  },
  {
    id: "2",
    assessmentName: "Q2 Assessment",
    startDate: "10/10/2019",
    endDate: "11/10/2020",
    openness: 1,
    conscientiousness: 2,
    extraversion: 3,
    agreeableness: 4,
    neuroticism: 5,
  },
  {
    id: "3",
    assessmentName: "Q3 Assessment",
    startDate: "10/10/2018",
    endDate: "10/10/2019",
    openness: 1,
    conscientiousness: 2,
    extraversion: 3,
    agreeableness: 4,
    neuroticism: 5,
  },
  {
    id: "4",
    assessmentName: "Q4 Assessment",
    startDate: "10/10/2021",
    endDate: "10/10/2022",
    openness: 1,
    conscientiousness: 2,
    extraversion: 3,
    agreeableness: 4,
    neuroticism: 5,
  },
  {
    id: "5",
    assessmentName: "Q2 Assessment",
    startDate: "10/10/2022",
    endDate: "10/10/2023",
    openness: 1,
    conscientiousness: 2,
    extraversion: 3,
    agreeableness: 4,
    neuroticism: 5,
  },
  {
    id: "6",
    assessmentName: "My Assessment",
    startDate: "10/10/2023",
    endDate: "10/10/2024",
    openness: 1,
    conscientiousness: 2,
    extraversion: 3,
    agreeableness: 4,
    neuroticism: 5,
  },
];
