import { Alert, Button, Snackbar, TextField } from "@mui/material";
import Grid from "@mui/material/Grid";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import axios from "axios";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { validateToken } from "../../utils/localStorageHandling";
import "./assessment.scss";

function Assessment(props) {
  const [assessmentName, setAssessmentName] = useState();
  const [startDate, setStartDate] = useState(
    props.startDate ? props.startDate : dayjs()
  );
  const [endDate, setEndDate] = useState(
    props.endDate ? props.endDate : dayjs()
  );
  const [numberOfQuestions, setNumberOfQuestions] = useState();
  const [duration, setDuration] = useState();
  const [successSnackBar, setSuccessSnackBar] = useState(false);
  const [errorSnackbar, setErrorSnackBar] = useState(false);
  const [errorNumberOfQuestions, setErrorNumberOfQuestions] = useState(false);
  const navigate = useNavigate();


  const clearForm =() => {
    setAssessmentName();
    setStartDate(dayjs());
    setEndDate(dayjs());
    setNumberOfQuestions();
    setDuration();
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    if (errorNumberOfQuestions) {
      return;
    }
    if(props.type === "fresh"){
    axios
      .post(process.env.REACT_APP_ROOT_URL+"/api/v1/assessment", {
        assessmentName,
        startDate,
        endDate,
        numberOfQuestions,
        duration,
        companyId: localStorage.getItem("companyId") ? localStorage.getItem("companyId") : 123,
      },{headers: { Authorization: localStorage.getItem("authHeader") },})
      .then(function (response) {
        console.log(response);
        setSuccessSnackBar(true);
        clearForm();
      })
      .catch(function (error) {
        console.log(error);
        setErrorSnackBar(true);
      });
    }else{
      axios
      .put(process.env.REACT_APP_ROOT_URL+"/api/v1/assessment", {
        id: props.params.assessmentId,
        assessmentName,
        startDate,
        endDate,
        numberOfQuestions,
        duration,
        companyId: localStorage.getItem("companyId") ? localStorage.getItem("companyId") : 123,
      },{headers: { Authorization: localStorage.getItem("authHeader") },})
      .then(function (response) {
        console.log(response);
        setSuccessSnackBar(true);
        //clearForm();
      })
      .catch(function (error) {
        console.log(error);
        setErrorSnackBar(true);
      });
    }
  };

  useEffect(() => {
    validateToken("admin", navigate);
    if (props.type === "view") {
      axios
        .get(process.env.REACT_APP_ROOT_URL+"/api/v1/assessment", {
          params: {
            assessmentId: props.params.assessmentId
              ? props.params.assessmentId
              : 0,
          },
          headers: { Authorization: localStorage.getItem("authHeader") },
        })
        .then((response) => {
          console.log(response.data);
          setAssessmentName(response.data.assessmentName);
          setStartDate(response.data.startDate);
          setEndDate(response.data.endDate);
          setNumberOfQuestions(response.data.numberOfQuestions);
          setDuration(response.data.duration);
        });
    } 
  },[]);

  const handleChangeAssessmentName = (event) => {
    //console.log(event);
    setAssessmentName(event.target.value);
  };
  const handleChangeStartDate = (newValue) => {
    //TODO: start date validations
    console.log(newValue.$d);
    setStartDate(newValue);
  };
  const handleChangeEndDate = (newValue) => {
    //TODO: end date validation
    setEndDate(newValue);
  };
  const handleChangeNumberOfQuestions = (event) => {
    //TODO: mulitple of 5 validation
    if (parseInt(event.target.value) % 5 !== 0) {
      setErrorNumberOfQuestions(true);
    } else {
      setErrorNumberOfQuestions(false);
    }
    setNumberOfQuestions(event.target.value);
  };
  const handleChangeduration = (event) => {
    setDuration(event.target.value);
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSuccessSnackBar(false);
    setErrorSnackBar(false);
  };

  /*   useEffect(() => {
    console.log(props.type);
  }); */
  return (
    <div className="experiment2">
      <form onSubmit={handleSubmit}>
        <Grid
          container
          direction="column"
          spacing={3}
          justifyContent="center"
          alignItems="flex-start"
        >
          <Grid item xs className="gridItem">
            <TextField
              required
              fullWidth
              id="standard-basic"
              label="Assessment Name"
              variant="outlined"
              value={assessmentName?assessmentName: ""}
              onChange={handleChangeAssessmentName}
              InputProps={{ readOnly: props.type !== "fresh" }}
            />
          </Grid>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Grid item xs className="gridItem">
              <DesktopDatePicker
                label="Start Date"
                inputFormat="MM/DD/YYYY"
                value={startDate?startDate:undefined}
                onChange={handleChangeStartDate}
                readOnly={props.type !== "fresh"}
                renderInput={(params) => (
                  <TextField required fullWidth {...params} />
                )}
              />
            </Grid>
            <Grid item xs className="gridItem">
              <DesktopDatePicker
                label="End Date"
                inputFormat="MM/DD/YYYY"
                value={endDate?endDate:undefined}
                onChange={handleChangeEndDate}
               // readOnly={props.type !== "fresh"}
                renderInput={(params) => (
                  <TextField required fullWidth {...params} />
                )}
              />
            </Grid>
          </LocalizationProvider>
          <Grid item xs className="gridItem">
            <TextField
              required
              fullWidth
              error={errorNumberOfQuestions}
              id="standard-basic"
              label="Number of Questions"
              variant="outlined"
              type="number"
              value={numberOfQuestions?numberOfQuestions:""}
              onChange={handleChangeNumberOfQuestions}
              InputProps={{ readOnly: props.type !== "fresh" }}
              helperText={errorNumberOfQuestions ? "Need multiple of 5" : ""}
            />
          </Grid>
          <Grid item xs className="gridItem">
            <TextField
              required
              fullWidth
              id="standard-basic"
              label="Assessment Duration(in min)"
              variant="outlined"
              type="number"
              value={duration?duration:""}
              onChange={handleChangeduration}
              InputProps={{ readOnly: props.type !== "fresh" }}
            />
          </Grid>
            <Grid item xs className="gridItem">
              <Button
                type="submit"
                variant="contained"
                className="createButton"
              >
                {props.type!=="fresh" ? "Update" : "Create" }
              </Button>
            </Grid>
        </Grid>
      </form>
      <Snackbar
        open={successSnackBar}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert onClose={handleClose} severity="success" sx={{ width: "100%" }}>
          Assessment {props.type==="fresh" ? "Created" : "Updated"} Successfully!
        </Alert>
      </Snackbar>
      <Snackbar
        open={errorSnackbar}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
          Error in {props.type==="fresh" ? "creating" : "updating"} Assessment.Please contact Support !
        </Alert>
      </Snackbar>
    </div>
  );
}

export default Assessment;
