import dayjs from "dayjs";
import moment from "moment/moment";

export const assessmentColumns = [
  // { field: "id", headerName: "ID", width: 70 },
  {
    field: "assessmentName",
    headerName: "Assessment Name",
    minWidth: 160,
    flex: 1.6,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "startDate",
    headerName: "Start Date",
    type: "date",
    minWidth: 110,
    flex: 1.1,
    align: "center",
    headerAlign: "center",
    valueGetter: (params) =>
      moment(params.row.startDate, "YYYY-MM-DD").format("DD-MM-YYYY"),
  },
  {
    field: "endDate",
    headerName: "End Date",
    type: "date",
    minWidth: 110,
    flex: 1.1,
    align: "center",
    headerAlign: "center",
    valueGetter: (params) =>
      moment(params.row.endDate, "YYYY-MM-DD").format("DD-MM-YYYY"),
  },
  {
    field: "numberOfQuestions",
    headerName: "Number of Questions",
    type: "number",
    minWidth: 160,
    flex: 1.6,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "duration",
    headerName: "Duration(in min)",
    type: "number",
    minWidth: 120,
    flex: 1.2,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "percentageCompletion",
    headerName: "Completion",
    type: "number",
    minWidth: 90,
    flex: 0.9,
    align: "center",
    headerAlign: "center",
    renderCell: (params) => (params.row.percentageCompletion)+'%'
  },
  {
    field: "status",
    headerName: "Status",
    minWidth: 100,
    flex: 1,
    align: "center",
    headerAlign: "center",
    renderCell: (params) => (moment(params.row.endDate).isSameOrAfter(new Date(), 'day') ? ' STARTED' : "COMPLETED") 
  },
];

//Temp data
export const assessmentRows = [
  {
    id: "1",
    asssessmentName: "Q1 Assessment",
    startDate: "10/10/2020",
    endDate: "12/10/2020",
    numberofQuestions: 5,
    duration: 5,
    status: "Started",
  },
  {
    id: "2",
    asssessmentName: "Q2 Assessment",
    startDate: "10/10/2019",
    endDate: "11/10/2020",
    numberofQuestions: 10,
    duration: 10,
    status: "Completed",
  },
  {
    id: "3",
    asssessmentName: "Q3 Assessment",
    startDate: "10/10/2018",
    endDate: "10/10/2019",
    numberofQuestions: 15,
    duration: 7,
    status: "Started",
  },
  {
    id: "4",
    asssessmentName: "Q4 Assessment",
    startDate: "10/10/2021",
    endDate: "10/10/2022",
    numberofQuestions: 10,
    duration: 15,
    status: "Cancelled",
  },
  {
    id: "5",
    asssessmentName: "Q2 Assessment",
    startDate: "10/10/2022",
    endDate: "10/10/2023",
    numberofQuestions: 15,
    duration: 5,
    status: "Started",
  },
  {
    id: "6",
    asssessmentName: "My Assessment",
    startDate: "10/10/2023",
    endDate: "10/10/2024",
    numberofQuestions: 20,
    duration: 10,
    status: "Completed",
  },
];
