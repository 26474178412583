import React from "react";
import "./processComponent.scss";
import arrow from './arrow.png';

function ProcessComponent() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      // width="1920"
      // height="1266"
      viewBox="0 0 1920 1266"
      className="myprocesscomponent"
    >
      <defs>
        <clipPath id="clip-path">
          <path
            fill="#fff"
            stroke="#707070"
            strokeWidth="1"
            d="M0 0H415V434H0z"
            data-name="Rectangle 44"
            transform="translate(1505 1118)"
          ></path>
        </clipPath>
        <clipPath id="clip-path-2">
          <path
            fill="#fff"
            stroke="#707070"
            strokeWidth="1"
            d="M0 0H467V423H0z"
            data-name="Rectangle 43"
            transform="translate(0 1832)"
          ></path>
        </clipPath>
        <radialGradient
          id="radial-gradient"
          cx="0.5"
          cy="0.446"
          r="0.5"
          gradientUnits="objectBoundingBox"
        >
          <stop offset="0" stopColor="#1c72b4"></stop>
          <stop offset="1" stopColor="#f3f8fb"></stop>
        </radialGradient>
        <clipPath id="clip-page_2">
          <path d="M0 0H1920V1266H0z"></path>
        </clipPath>
      </defs>
      <g clipPath="url(#clip-page_2)" data-name="page 2">
        <path fill="#fff" d="M0 0H1920V1266H0z"></path>
        <text
          data-name="How it works"
          fontFamily="GothamPro-Bold, Gotham Pro"
          fontSize="60"
          fontWeight="700"
          transform="translate(763 186)"
        >
          <tspan x="0" y="0">
            How it works
          </tspan>
        </text>
        {/* {<image width="100" height="100" href={arrow} />} */}
        <path
          fill="none"
          stroke="#1c72b4"
          strokeWidth="5"
          d="M0 0L61.5 0"
          data-name="Line 4"
          transform="translate(648 164)"
        ></path>
        <path
          fill="none"
          stroke="#1c72b4"
          strokeWidth="5"
          d="M0 0L61.5 0"
          data-name="Line 5"
          transform="translate(1211.5 164)"
        ></path>
        <g
          clipPath="url(#clip-path)"
          data-name="Mask Group 5"
          transform="translate(0 -1118)"
        >
          <g data-name="Group 64">
            <g fill="none" data-name="Polygon 5" opacity="0.1">
              <path
                d="M224.693 0l224.693 163.25-85.825 264.143H85.825L0 163.25z"
                transform="translate(1543 973.304)"
              ></path>
               <image width="100" height="100" href={arrow} />
              <path
                fill="#1c72b4"
                d="M224.693 2.472L2.35 164.014l84.927 261.379h274.83l84.926-261.38L224.693 2.474m0-2.473l224.693 163.25-85.825 264.143H85.825L0 163.25 224.693 0z"
                transform="translate(1543 973.304)"
              ></path>
              
            </g>
            <path
              fill="#f3f8fb"
              d="M116.712 0l116.712 84.8-44.58 137.2H44.58L0 84.8z"
              data-name="Polygon 7"
              transform="translate(1650.981 1085)"
            ></path>
          </g>
        </g>
        <g
          clipPath="url(#clip-path-2)"
          data-name="Mask Group 6"
          transform="translate(0 -987)"
        >
          <g data-name="Group 65">
            <g fill="none" data-name="Polygon 6" opacity="0.1">
              <path
                d="M246.1 0l246.1 178.806-94 289.314H94L0 178.806z"
                transform="translate(-72.208 1919.655)"
              ></path>
              <path
                fill="#1c72b4"
                d="M246.104 2.472L2.35 179.57l93.105 286.55h301.295l93.105-286.55L246.104 2.471m0-2.472l246.103 178.806-94.003 289.313h-304.2L0 178.806 246.104 0z"
                transform="translate(-72.208 1919.655)"
              ></path>
            </g>
            <path
              fill="#f3f8fb"
              d="M133.1 0l133.1 96.3-50.834 155.819H50.841L0 96.3z"
              data-name="Polygon 8"
              transform="translate(40.792 2037.655)"
            ></path>
          </g>
        </g>
        <path
          fill="#1c72b4"
          d="M163.291 0l163.29 120-62.371 194.158H62.372L0 120z"
          data-name="Polygon 22"
          opacity="0.05"
          transform="translate(786.606 607.484)"
        ></path>
        <ellipse
          cx="93"
          cy="92.5"
          fill="#1c72b4"
          data-name="Ellipse 70"
          rx="93"
          ry="92.5"
          transform="translate(857 392)"
        ></ellipse>
        <text
          fill="#fff"
          data-name="Create a time-bound assessment"
          //fontFamily="GothamPro, Gotham Pro"
          fontSize="18"
          transform="translate(950 484)"
        >
          <tspan x="-37.368" y="0">
            Create a
          </tspan>
          <tspan x="-52.416" y="21">
            time-bound
          </tspan>
          <tspan x="-51.354" y="42">
            assessment
          </tspan>
        </text>
        <text
          fill="#fff"
          data-name="1"
          //fontFamily="Outfit"
          fontSize="43"
          fontWeight="500"
          transform="translate(940 449)"
        >
          <tspan x="0" y="0">
            1
          </tspan>
        </text>
        <circle
          cx="93"
          cy="93"
          r="93"
          fill="#1c72b4"
          data-name="Ellipse 71"
          transform="translate(580 600)"
        ></circle>
        <text
          fill="#fff"
          data-name="Select multiple teams to start the assessment"
          //fontFamily="GothamPro, Gotham Pro"
          fontSize="18"
          transform="translate(672 694)"
        >
          <tspan x="-66.402" y="0">
            Select multiple
          </tspan>
          <tspan x="-62.316" y="21">
            teams to start
          </tspan>
          <tspan x="-68.562" y="42">
            the assessment
          </tspan>
        </text>
        <text
          fill="#fff"
          data-name="2"
          fontFamily="Outfit"
          fontSize="41"
          fontWeight="500"
          transform="translate(662 656)"
        >
          <tspan x="0" y="0">
            2
          </tspan>
        </text>
        <circle
          cx="93"
          cy="93"
          r="93"
          fill="#1c72b4"
          data-name="Ellipse 48"
          transform="translate(1134 600)"
        ></circle>
        <text
          fill="#fff"
          data-name="Enhance the appraisal process"
          //fontFamily="GothamPro, Gotham Pro"
          fontSize="18"
          transform="translate(1227 692)"
        >
          <tspan x="-38.205" y="0">
            Enhance
          </tspan>
          <tspan x="-57.429" y="21">
            the appraisal
          </tspan>
          <tspan x="-34.443" y="42">
            process
          </tspan>
        </text>
        <text
          fill="#fff"
          data-name="5"
          fontFamily="Outfit"
          fontSize="43"
          fontWeight="500"
          transform="translate(1216 658)"
        >
          <tspan x="0" y="0">
            5
          </tspan>
        </text>
        <ellipse
          cx="93"
          cy="92.5"
          fill="#1c72b4"
          data-name="Ellipse 72"
          rx="93"
          ry="92.5"
          transform="translate(694 923)"
        ></ellipse>
        <text
          fill="#fff"
          data-name="Employees complete the assessment through easy web-portal"
          //fontFamily="GothamPro, Gotham Pro"
          fontSize="16"
          transform="translate(786 1018)"
        >
          <tspan x="-82.88" y="0">
            Employees complete
          </tspan>
          <tspan x="-60.944" y="18">
            the assessment
          </tspan>
          <tspan x="-51.92" y="36">
            through easy
          </tspan>
          <tspan x="-43.688" y="54">
            web-portal
          </tspan>
        </text>
        <text
          fill="#fff"
          data-name="3"
          fontFamily="Outfit"
          fontSize="41"
          fontWeight="500"
          transform="translate(776 979)"
        >
          <tspan x="0" y="0">
            3
          </tspan>
        </text>
        <ellipse
          cx="93"
          cy="92.5"
          fill="#1c72b4"
          data-name="Ellipse 73"
          rx="93"
          ry="92.5"
          transform="translate(1019 923)"
        ></ellipse>
        <text
          fill="#fff"
          data-name="Detailed report at individual/team /organization level"
          // fontFamily="GothamPro, Gotham Pro"
          fontSize="17"
          transform="translate(1113 1015)"
        >
          <tspan x="-64.031" y="0">
            Detailed report
          </tspan>
          <tspan x="-77.01" y="21">
            at individual/team
          </tspan>
          <tspan x="-56.474" y="42">
            /organization
          </tspan>
          <tspan x="-19.057" y="63">
            level
          </tspan>
        </text>
        <text
          fill="#fff"
          data-name="4"
          fontFamily="Outfit"
          fontSize="42"
          fontWeight="500"
          transform="translate(1098 980)"
        >
          <tspan x="0" y="0">
            4
          </tspan>
        </text>
        <g
          fill="none"
          stroke="#1c72b4"
          strokeWidth="1"
          data-name="Ellipse 74"
          opacity="0.5"
          transform="translate(845 379)"
        >
          <circle cx="105" cy="105" r="105" stroke="none"></circle>
          <circle cx="105" cy="105" r="104.5"></circle>
        </g>
        <g
          fill="none"
          stroke="#1c72b4"
          strokeWidth="1"
          data-name="Ellipse 75"
          opacity="0.5"
          transform="translate(567 587)"
        >
          <circle cx="105.5" cy="105.5" r="105.5" stroke="none"></circle>
          <circle cx="105.5" cy="105.5" r="105"></circle>
        </g>
        <g
          fill="none"
          stroke="#1c72b4"
          strokeWidth="1"
          data-name="Ellipse 76"
          opacity="0.5"
          transform="translate(1122 587)"
        >
          <circle cx="105.5" cy="105.5" r="105.5" stroke="none"></circle>
          <circle cx="105.5" cy="105.5" r="105"></circle>
        </g>
        <g
          fill="none"
          stroke="#1c72b4"
          strokeWidth="1"
          data-name="Ellipse 77"
          opacity="0.5"
          transform="translate(1006 911)"
        >
          <ellipse
            cx="105.5"
            cy="105"
            stroke="none"
            rx="105.5"
            ry="105"
          ></ellipse>
          <ellipse cx="105.5" cy="105" rx="105" ry="104.5"></ellipse>
        </g>
        <g
          fill="none"
          stroke="#1c72b4"
          strokeWidth="1"
          data-name="Ellipse 78"
          opacity="0.5"
          transform="translate(681 911)"
        >
          <ellipse
            cx="105.5"
            cy="105"
            stroke="none"
            rx="105.5"
            ry="105"
          ></ellipse>
          <ellipse cx="105.5" cy="105" rx="105" ry="104.5"></ellipse>
        </g>
        <path
          fill="#b5577d"
          d="M10.893 55z"
          data-name="Path 15"
          transform="translate(772.083 615.119)"
        ></path>
        <g data-name="Group 73" transform="translate(729.234 528.057)">
          <path
            fill="#1c72b4"
            d="M13.8 0l13.8 20.874H0z"
            data-name="Polygon 26"
            transform="rotate(-130 34.381 50.572)"
          ></path>
          <path
            fill="none"
            stroke="#1c72b4"
            strokeWidth="6"
            d="M0 86.236L118.007 0"
            data-name="Line 9"
            transform="translate(23.331)"
          ></path>
        </g>
        <path
          fill="none"
          stroke="#1c72b4"
          strokeWidth="6"
          d="M0 0L143.423 0"
          data-name="Line 10"
          transform="translate(868.757 1055.456)"
        ></path>
        <g data-name="Group 71" transform="translate(672.684 777.686)">
          <path
            fill="none"
            stroke="#1c72b4"
            strokeWidth="6"
            d="M0 0L49.926 155.224"
            data-name="Line 8"
          ></path>
          <path
            fill="#1c72b4"
            d="M13.8 0l13.8 20.874H0z"
            data-name="Polygon 27"
            transform="rotate(159 18.897 87.436)"
          ></path>
        </g>
        <path
          fill="#1c72b4"
          d="M13.8 0l13.8 20.874H0z"
          data-name="Polygon 28"
          transform="rotate(90 -6.795 1034.861)"
        ></path>
        <g data-name="Group 72" transform="translate(1147.188 777.686)">
          <path
            fill="none"
            stroke="#1c72b4"
            strokeWidth="6"
            d="M0 155.224L49.926 0"
            data-name="Line 8"
            transform="translate(0 16.959)"
          ></path>
          <path
            fill="#1c72b4"
            d="M13.8 0l13.8 20.874H0z"
            data-name="Polygon 27"
            transform="rotate(21 21.053 113.592)"
          ></path>
        </g>
        <g data-name="Group 74" transform="translate(1021.074 518.979)">
          <path
            fill="#1c72b4"
            d="M13.8 0l13.8 20.874H0z"
            data-name="Polygon 26"
            transform="rotate(-50 22.67 10.572)"
          ></path>
          <path
            fill="none"
            stroke="#1c72b4"
            strokeWidth="6"
            d="M0 0L118.007 86.236"
            data-name="Line 9"
            transform="translate(23.331 23.181)"
          ></path>
          
        </g>
                  <path
            fill="url(#radial-gradient)"
            d="M49.471 155.631A81.024 81.024 0 01107.262 4.348l-5.036 5.8a74.084 74.084 0 1033.574 21.22L125.86 42.8l-9.71-31.772 32.813 5.193-8.5 9.78a81.021 81.021 0 01-90.992 129.63z"
            data-name="Union 3"
            transform="translate(869 699.461)"
          ></path>
          
      
      </g>
    </svg>
  );
}

export default ProcessComponent;
