export const userColumns = [
  // { field: "id", headerName: "ID", width: 70 },
  {
    field: "firstName",
    headerName: "First name",
    minWidth: 110,
    flex:1.1,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "lastName",
    headerName: "Last name",
    minWidth: 110,
    flex: 1.1,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "employeeEmail",
    headerName: "Email",
    minWidth: 220,
    flex:2.2,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "openness",
    headerName: "Openness",
    minWidth: 80,
    flex:0.8,
    valueGetter: (params) => params.row.openness.toFixed(1),
    align: "center",
    headerAlign: "center",
  },
  {
    field: "conscientiousness",
    headerName: "Conscientiousness",
    type: "number",
    minWidth: 140,
    flex: 1.4,
    valueGetter: (params) => params.row.conscientiousness.toFixed(1),
    align: "center",
    headerAlign: "center",
  },
  {
    field: "extraversion",
    headerName: "Extraversion",
    type: "number",
    minWidth: 100,
    flex:1,
    valueGetter: (params) => params.row.extraversion.toFixed(1),
    align: "center",
    headerAlign: "center",
  },
  {
    field: "agreeableness",
    headerName: "Agreeableness",
    type: "number",
    minWidth: 110,
    flex:1.1,
    valueGetter: (params) => params.row.agreeableness.toFixed(1),
    align: "center",
    headerAlign: "center",
  },
  {
    field: "neuroticism",
    headerName: "Neuroticism",
    type: "number",
    minWidth: 100,
    flex:1,
    valueGetter: (params) => params.row.neuroticism.toFixed(1),
    align: "center",
    headerAlign: "center",
  },
];

//Temp data
export const userRows = [
  {
    id: "1",
    lastName: "Snow",
    firstName: "Jon",
    email: "jon.snow@gmail.com",
    openness: 1,
    conscientiousness: 2,
    extraversion: 3,
    agreeableness: 4,
    neuroticism: 5,
  },
  {
    id: "2",
    lastName: "Snowy",
    firstName: "Jony",
    email: "jon.snow@gmail.com",
    openness: 1,
    conscientiousness: 2,
    extraversion: 3,
    agreeableness: 4,
    neuroticism: 5,
  },
  {
    id: "3",
    lastName: "Singhal",
    firstName: "Shashank",
    email: "shashank.snow@gmail.com",
    openness: 5,
    conscientiousness: 2,
    extraversion: 3.5,
    agreeableness: 4,
    neuroticism: 5,
  },
  {
    id: "4",
    lastName: "Snow",
    firstName: "Jon",
    email: "jon.snow@gmail.com",
    openness: 1,
    conscientiousness: 2,
    extraversion: 3,
    agreeableness: 4,
    neuroticism: 5,
  },
  {
    id: "5",
    lastName: "Mayank",
    firstName: "Jon",
    email: "jon.snow@gmail.com",
    openness: 1,
    conscientiousness: 2,
    extraversion: 3,
    agreeableness: 4,
    neuroticism: 5,
  },
  {
    id: "6",
    lastName: "Snow",
    firstName: "Singhal",
    email: "jon.snow@gmail.com",
    openness: 1,
    conscientiousness: 2.5,
    extraversion: 3,
    agreeableness: 4,
    neuroticism: 5,
  },
  {
    id: "7",
    lastName: "Snow",
    firstName: "Jon",
    email: "jon.snow@gmail.com",
    openness: 5,
    conscientiousness: 2,
    extraversion: 3,
    agreeableness: 4,
    neuroticism: 5,
  },
  {
    id: "8",
    lastName: "Snow",
    firstName: "Jon",
    email: "jon.snow@gmail.com",
    openness: 1,
    conscientiousness: 2,
    extraversion: 3,
    agreeableness: 4,
    neuroticism: 5,
  },
];

/*   const columns = [
    { field: "id", headerName: "ID", width: 70 },
    { field: "firstName", headerName: "First name", width: 130 },
    { field: "lastName", headerName: "Last name", width: 130 },
    {
      field: "age",
      headerName: "Age",
      type: "number",
      width: 90,
    },
    {
      field: "fullName",
      headerName: "Full name",
      description: "This column has a value getter and is not sortable.",
      sortable: false,
      width: 160,
      valueGetter: (params) =>
        `${params.row.firstName || ""} ${params.row.lastName || ""}`,
    },
    {
      field: "email",
      headerName: "Email Address",
      description: "This column has a value getter and is not sortable.",
      sortable: false,
      width: 160,
      renderCell:(params)=>{
          return (
              <>
              <span>{params.row.lastName}</span>
              <span>{params.row.age}</span>
              </>
          )
      }
  
    },
  ];
  
  const rows = [
    { id: 1, lastName: "Snow", firstName: "Jon", age: 35 },
    { id: 2, lastName: "Lannister", firstName: "Cersei", age: 42 },
    { id: 3, lastName: "Lannister", firstName: "Jaime", age: 45 },
    { id: 4, lastName: "Stark", firstName: "Arya", age: 16 },
    { id: 5, lastName: "Targaryen", firstName: "Daenerys", age: null },
    { id: 6, lastName: "Melisandre", firstName: null, age: 150 },
    { id: 7, lastName: "Clifford", firstName: "Ferrara", age: 44 },
    { id: 8, lastName: "Frances", firstName: "Rossini", age: 36 },
    { id: 9, lastName: "Roxie", firstName: "Harvey", age: 65 },
  ];
   */
