import { Outlet, Navigate } from 'react-router-dom'

const PrivateRoutesEmployee = () => {
    let auth = {'token':localStorage.getItem("jwt_employee"), 'authority':localStorage.getItem("authority")}
    
    return(
        auth.token ? <Outlet/> :<Navigate to="/employeeLogin" />
    )
}

export default PrivateRoutesEmployee