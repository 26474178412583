import { Outlet, Navigate } from 'react-router-dom'

const PrivateRoutesAdmin = () => {
    let auth = {'token':localStorage.getItem("jwt_admin"), 'authority':localStorage.getItem("authority")}
    
    return(
        auth.token ? <Outlet/> :<Navigate to="/adminLogin" />
    )
}

export default PrivateRoutesAdmin