import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import ScheduleIcon from "@mui/icons-material/Schedule";
import { Button, Grid, Paper, Typography } from "@mui/material";
import React from "react";
import data from "../../resources/data";
import "./task.scss";
//import data from "src/resources/data.js";
import { useNavigate } from "react-router-dom";

function Task(props) {
  let navigate = useNavigate();
  const handleSubmit = () => {
    data.questions = shuffleArray(props.questions);
    data.duration = props.assessment.duration;
    data.assessmentId = props.assessment.id;
    data.assessmentTaskId = props.assessmentTaskId;
    console.log("start clicked");
    let path = `/test`;
    navigate(path);

    /*       return(
        <Route path="/test" element={<TestPage questions={props.assessment.questions} />} />
      ) */
  };

  const shuffleArray = (array) => {
      for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
      }
      return array;
    
  }
  return (
    <Paper elevation={1} className="taskMain">
      <Grid
        container
        justifyContent="center"
        alignItems="flex-start"
        className="myGrid"
        spacing={3}
      >
        <Grid item xs={6} className="taskItem">
          <div class="myText">
            <Typography>
              Complete Task for <b>{props.assesseeName}</b> for{" "}
              <b>Assessment #{props.assessment.assessmentName}</b>
            </Typography>
          </div>
        </Grid>
        <Grid item xs className="taskItem">
          <ScheduleIcon />
          <div>{props.assessment.duration} min</div>
        </Grid>
        <Grid item xs={2} className="taskItemCalendar">
          <CalendarMonthIcon />
          <div> {props.assessment.endDate.slice(0,10)}</div>
        </Grid>
        <Grid item xs className="taskItemButton">
          <Button variant="contained" onClick={handleSubmit}>
            Start
          </Button>
        </Grid>
      </Grid>
    </Paper>
  );
}

export default Task;
